/**
 * Nav Style
**/
/**=========================================
* .gnav-logo
=========================================*/
.gnav-logo {
  > a {
    overflow: hidden;
    display: block;
    padding: 17px 14px 0;
    line-height: 1;
    height: 50px;
    width: 200px;
    transition: width .3s ease-in-out;
    text-align: center;
    > img {
      min-width: 120px;
      width: 120px;
    }
  }
}
.is-gnav-close {
  .gnav-logo {
    > a {
      width: 40px;
    }
  }
}
.js-gnav-default-close {
  .gnav-logo > a {
    transition: width 0s;
  }
}
/**=========================================
* gnav-content scrollbar custom
=========================================*/
.gnav-content {
  .mCSB_scrollTools {
    background: transparent;
    width: 8px;
  }
  .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: rgba(#afc0d1,0.7);
  }
  .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: rgba(#afc0d1,0.7);
  }
  .mCS-autoHide,
  .mCustomScrollBox {
    .mCSB_scrollTools {
      opacity: 0;
      transition: 0s;
    }
    &:hover {
      .mCSB_scrollTools {
        opacity: 1 !important;
      }
    }
  }
}
/**=========================================
* gnav-content
=========================================*/
.gnav-content {
  position: relative;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  // margin-right: -20px;
  height: calc(100vh - 100px);
  &-scroll {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    // padding-right: 20px;
    // width: 220px;
    width: 200px;
  }
}
.gnav-item {
  position: relative;
  display: block;
  padding: (14-13*0.4/2)+px 17px (14-13*0.4/2)+px 50px;
  font-size: 1.3rem;
  line-height: 1.4;
  transition: background-color .2s;
  .icon:first-child {
    position: absolute;
    top: (14-13*0.4/2)+px;
    left: 17px;
  }
  .icon-home {
    font-size: 1.4rem;
    margin-left: 1px;
  }
  .icon-arrow01 {
    position: absolute;
    top: (14-13*0.4/2+2)+px;
    right: 15px;
    font-size: 1rem;
    color: #607d99;
  }
  &--link {
    &.current {
      background-color: #1f2e3d;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        border-top: 5px solid transparent;
        border-right: 6px solid #fff;
        border-bottom: 5px solid transparent;
        border-left: 6px solid transparent;
        margin-top: -5px;
        width: 0;
        height: 0;
      }
      .text {
        color: #fff;
      }
      .icon:first-child {
        color: $key-color;
      }
    }
  }
  &.current {
    .icon:first-child {
      color: $key-color;
    }
  }
  &.is-toggle-button-active {
    .icon-arrow01 {
      transform: rotate(180deg);
    }
  }
}
//トグルボタン
.gnav-item--child {
  &.current {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background-color: $key-color;
      width: 4px;
    }
  }
}
//カラー設定
.page-backend {
  .gnav {
    color: #afc0d1;
  }
  .gnav-item {
    color: #afc0d1;
    .icon {
      color: #afc0d1;
    }
    &:hover {
      background-color: #34495e;
    }
  }
  .gnav-item--link {
    &.current {
      background-color: #1f2e3d;
      .text {
        color: #fff;
      }
    }
  }
}
.page-merchant,
.page-partner {
  .gnav {
    color: #3e5c78;
  }
  .gnav-item {
    color: #3e5c78;
    .icon {
      color: #3e5c78;
    }
    &:hover {
      background-color: #d0d9e1;
    }
  }
  .gnav-item--link {
    &.current {
      background-color: #d0d9e1;
      .text {
        color: #000;
      }
    }
  }
}
/**=========================================
* gnav-close-button
=========================================*/
.gnav-close-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 50px;
  width: 100%;
  padding-left: 23px;
  transition: .2s;
  &:hover {
    background-color: #34495e;
  }
  > .icon {
    transform: rotate(90deg);
    font-size: 1.2rem;
  }
  &.is-gnav-close {
    padding-left: 21px;
    > .icon {
      transform: rotate(-90deg);
    }
  }
}
//カラー設定
.page-backend {
  .gnav-close-button {
    &:hover {
      background-color: #34495e;
    }
    > .icon {
      color: #afc0d1;
    }
  }
}
.page-merchant,
.page-partner {
  .gnav-close-button {
    &:hover {
      background-color: #d0d9e1;
    }
    > .icon {
      color: #3e5c78;
    }
  }
}
/**=========================================
* topic-path
=========================================*/
.topic-path {
  background-color: $color-gray04;
  border-bottom: 1px solid #dddddd;
  padding: 5px 20px 6px;
  line-height: 1;
  > span {
    display: inline-block;
    font-size: 1.1rem;
    &:after {
      content: ">";
      margin-left: 10px;
      margin-right: 10px;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    a {
      color: $color-blue;
      &:hover {
        text-decoration: underline;
      }
    }
  }

}
/**=========================================
* table_nav
=========================================*/
.table_nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fcfcfc;
  margin-top: 15px;
  padding: 5px 10px;
  &-controls {
    flex: 1;
  }
}
.table_nav-controls {
  display: inline-flex;
  justify-content: flex-end;
}
//合計の件数表示
.table_nav-info {
  font-size: 1.4rem;
  .num {
    color: $key-color;
  }
}
//行数指定
.table_nav-row {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  .text {
    font-size: 1.2rem;
  }
  .m-form-select {
    margin-left: 5px;
    width: 64px;
    text-align: right;
    .minict_wrapper span {
      padding: 5px 28px 4px 10px;
      min-height: inherit;
      font-size: 1.3rem;
    }
    .minict_wrapper:before {
      height: 24px;
      width: 24px;
    }
  }
}
//ページ数指定
.table_nav-movepage {
  display: inline-flex;
  align-items: center;
  margin-left: 20px;
  font-size: 1.3rem;
  input[type="text"] {
    margin-right: 4px;
    padding: 2px 10px 0px;
    width: 44px;
    height: 24px;
    min-height: inherit;
    text-align: right;
    font-size: 1.3rem;
    line-height: 1;
  }
  .m-button--radius {
    padding: 5px 11px;
    font-size: 1.2rem;
  }
}
//ページネート
.table_nav-paginate {
  display: inline-flex;
  align-items: center;
  margin-left: 15px;
  .m-icon-button {
    width: 26px;
    height: 26px;
  }
  .previous {
    .icon {
      padding-right: 2px;
    }
  }
  .next {
    .icon {
      padding-left: 2px;
    }
  }
}
/**=========================================
* table-scroll-nav
=========================================*/
.table-scroll-nav {
  cursor: pointer;
  position: fixed;
  top: 65px;//最低値
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  transition: left .2s ease-in-out, right .2s ease-in-out, opacity .2s;
  //hidden
  opacity: 0;
  z-index: 20;
  &.is-nav-show {
    opacity: 1;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(#999,0.6);
    border-radius: 100%;
  }
  &.prev {
    left: 220px;
    transform: rotate(180deg);
  }
  &.next {
    right: 20px;
  }
  .icon {
    position: relative;
    z-index: 2;
    color: #fff;
    font-size: 1.4rem;
    &:before {
      vertical-align: top;
    }
  }
}
.is-gnav-close {
  .table-scroll-nav {
    &.prev {
      left: 70px;
    }
  }
}
