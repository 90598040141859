/**
 * helper class
**/
.reverse {
 flex-direction: row-reverse;
}
.ov-hidden {
  overflow: hidden;
}
.pos-relative {
  position: relative;
}
.is-hidden {
  display: none !important;
}
.border-bottom {
  border-bottom: 1px solid #dddddd !important;
}

/* Font size
========================================================================== */
/* Relative size */
.size-xsmall {
  font-size: 0.9rem !important;
}

.size-small {
  font-size: 1.1rem !important;
}

.size-medium {
  font-size: 1.2rem !important;
}

.size-medium-ex {
  font-size: 1.3rem !important;
}

.size-large {
  font-size: 1.4rem !important;
}

.size-xlarge {
  font-size: 1.6rem !important;
}

.size-xxlarge {
  font-size: 1.8rem !important;
}

.size-xxxlarge {
  font-size: 2rem !important;
}
/* Absolute size */
.size-10px {
  font-size: 1rem !important;
}

.size-12px {
  font-size: 1.2rem !important;
}

.size-14px {
  font-size: 1.4rem !important;
}

.size-16px {
  font-size: 1.6rem !important;
}

.size-18px {
  font-size: 1.8rem !important;
}

.size-20px {
  font-size: 2rem !important;
}

.size-22px {
  font-size: 2.2rem !important;
}

.size-24px {
  font-size: 2.4rem !important;
}

.size-26px {
  font-size: 2.6rem !important;
}

.size-28px {
  font-size: 2.8rem !important;
}

.size-30px {
  font-size: 3rem !important;
}

.size-34px {
  font-size: 3.4rem !important;
}
/* Display
========================================================================== */
.dn {
  display: none !important;
}

.db {
  display: block !important;
}

.di {
  display: inline !important;
}

.ib {
  display: inline-block !important;
}

.dt {
  display: table !important;
}

.dtc {
  display: table-cell !important;
}
/* Font
========================================================================== */
.weight-normal {
  font-weight: normal !important;
}
.weight-bold {
  font-weight: bold !important;
}
.text-line {
  text-decoration: underline !important;
}
.letter-05 {
  letter-spacing: -0.5px !important;
}
.letter-0 {
  letter-spacing: 0px !important;
}
.letter-1 {
  letter-spacing: -1px !important;
}
.letter-2 {
  letter-spacing: -2px !important;
}
.letter1 {
  letter-spacing: 1px !important;
}
/* Float
========================================================================== */
.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

/* Align
========================================================================== */
/* Horizontal */
.align-left {
  text-align: left !important;
}

.align-center {
  text-align: center !important;
}

.align-right {
  text-align: right !important;
}
/* Vertical */
.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-baseline {
  vertical-align: baseline !important;
}
/* Space
========================================================================== */
.space-normal {
  margin-bottom: 1rem !important;
}

.space-near {
  margin-bottom: 0.5rem !important;
}

.space-far {
  margin-bottom: 1.5rem !important;
}

.space-section {
  margin-bottom: 30px !important;
}

.space-module {
  margin-bottom: 25px !important;
}

.space-none {
  margin-bottom: 0 !important;
}
/* Margin
========================================================================== */
.mt-40 {
  margin-top: -40px !important;
}

.mt-30 {
  margin-top: -30px !important;
}

.mt-20 {
  margin-top: -20px !important;
}

.mt-15 {
  margin-top: -15px !important;
}

.mt-10 {
  margin-top: -10px !important;
}

.mt-5 {
  margin-top: -5px !important;
}

.mt-1 {
  margin-top: -1px !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mt1 {
  margin-top: 1px !important;
}

.mt2 {
  margin-top: 2px !important;
}

.mt3 {
  margin-top: 3px !important;
}

.mt4 {
  margin-top: 4px !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mt6 {
  margin-top: 6px !important;
}

.mt7 {
  margin-top: 7px !important;
}

.mt8 {
  margin-top: 8px !important;
}

.mt9 {
  margin-top: 9px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt35 {
  margin-top: 35px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt45 {
  margin-top: 45px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt55 {
  margin-top: 55px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mt80 {
  margin-top: 80px !important;
}

.mt100 {
  margin-top: 80px !important;
}

.mr-40 {
  margin-right: -40px !important;
}

.mr-30 {
  margin-right: -30px !important;
}

.mr-20 {
  margin-right: -20px !important;
}

.mr-15 {
  margin-right: -15px !important;
}

.mr-10 {
  margin-right: -10px !important;
}

.mr-8 {
  margin-right: -8px !important;
}

.mr-5 {
  margin-right: -5px !important;
}

.mr-2 {
  margin-right: -2px !important;
}

.mr-1 {
  margin-right: -1px !important;
}

.mr0 {
  margin-right: 0 !important;
}

.mr1 {
  margin-right: 1px !important;
}

.mr2 {
  margin-right: 2px !important;
}

.mr3 {
  margin-right: 3px !important;
}

.mr4 {
  margin-right: 4px !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mr6 {
  margin-right: 6px !important;
}

.mr7 {
  margin-right: 7px !important;
}

.mr8 {
  margin-right: 8px !important;
}

.mr9 {
  margin-right: 9px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mr25 {
  margin-right: 25px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.mr35 {
  margin-right: 35px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.mr45 {
  margin-right: 45px !important;
}

.mr50 {
  margin-right: 50px !important;
}

.mr55 {
  margin-right: 55px !important;
}

.mr60 {
  margin-right: 60px !important;
}

.mb-40 {
  margin-bottom: -40px !important;
}

.mb-30 {
  margin-bottom: -30px !important;
}

.mb-20 {
  margin-bottom: -20px !important;
}

.mb-15 {
  margin-bottom: -15px !important;
}

.mb-10 {
  margin-bottom: -10px !important;
}

.mb-5 {
  margin-bottom: -5px !important;
}

.mb-1 {
  margin-bottom: -1px !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb1 {
  margin-bottom: 1px !important;
}

.mb2 {
  margin-bottom: 2px !important;
}

.mb3 {
  margin-bottom: 3px !important;
}

.mb4 {
  margin-bottom: 4px !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mb6 {
  margin-bottom: 6px !important;
}

.mb7 {
  margin-bottom: 7px !important;
}

.mb8 {
  margin-bottom: 8px !important;
}

.mb9 {
  margin-bottom: 9px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb35 {
  margin-bottom: 35px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb45 {
  margin-bottom: 45px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb55 {
  margin-bottom: 55px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.ml-40 {
  margin-left: -40px !important;
}

.ml-30 {
  margin-left: -30px !important;
}

.ml-20 {
  margin-left: -20px !important;
}

.ml-15 {
  margin-left: -15px !important;
}

.ml-10 {
  margin-left: -10px !important;
}

.ml-8 {
  margin-left: -8px !important;
}

.ml-5 {
  margin-left: -5px !important;
}

.ml-2 {
  margin-left: -2px !important;
}

.ml-1 {
  margin-left: -1px !important;
}

.ml0 {
  margin-left: 0 !important;
}

.ml1 {
  margin-left: 1px !important;
}

.ml2 {
  margin-left: 2px !important;
}

.ml3 {
  margin-left: 3px !important;
}

.ml4 {
  margin-left: 4px !important;
}

.ml5 {
  margin-left: 5px !important;
}

.ml6 {
  margin-left: 6px !important;
}

.ml7 {
  margin-left: 7px !important;
}

.ml8 {
  margin-left: 8px !important;
}

.ml9 {
  margin-left: 9px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml25 {
  margin-left: 25px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.ml35 {
  margin-left: 35px !important;
}

.ml40 {
  margin-left: 40px !important;
}

.ml45 {
  margin-left: 45px !important;
}

.ml50 {
  margin-left: 50px !important;
}

.ml55 {
  margin-left: 55px !important;
}

.ml60 {
  margin-left: 60px !important;
}


.mt-40 {
  margin-top: -40px !important;
}

.mt-30 {
  margin-top: -30px !important;
}

.mt-20 {
  margin-top: -20px !important;
}

.mt-15 {
  margin-top: -15px !important;
}

.mt-10 {
  margin-top: -10px !important;
}

.mt-5 {
  margin-top: -5px !important;
}

.mt-1 {
  margin-top: -1px !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mt1 {
  margin-top: 1px !important;
}

.mt2 {
  margin-top: 2px !important;
}

.mt3 {
  margin-top: 3px !important;
}

.mt4 {
  margin-top: 4px !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mt6 {
  margin-top: 6px !important;
}

.mt7 {
  margin-top: 7px !important;
}

.mt8 {
  margin-top: 8px !important;
}

.mt9 {
  margin-top: 9px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt35 {
  margin-top: 35px !important;
}

/* Padding
========================================================================== */
.pt0 {
  padding-top: 0 !important;
}

.pt1 {
  padding-top: 1px !important;
}

.pt2 {
  padding-top: 2px !important;
}

.pt3 {
  padding-top: 3px !important;
}

.pt4 {
  padding-top: 4px !important;
}

.pt5 {
  padding-top: 5px !important;
}

.pt6 {
  padding-top: 6px !important;
}

.pt7 {
  padding-top: 7px !important;
}

.pt8 {
  padding-top: 8px !important;
}

.pt9 {
  padding-top: 9px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pt15 {
  padding-top: 15px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.pt25 {
  padding-top: 25px !important;
}

.pt30 {
  padding-top: 30px !important;
}

.pt35 {
  padding-top: 35px !important;
}

.pt40 {
  padding-top: 40px !important;
}

.pt45 {
  padding-top: 45px !important;
}

.pt50 {
  padding-top: 50px !important;
}

.pt55 {
  padding-top: 55px !important;
}

.pt60 {
  padding-top: 60px !important;
}

.pt65 {
  padding-top: 65px !important;
}

.pt80 {
  padding-top: 80px !important;
}

.pr0 {
  padding-right: 0 !important;
}

.pr1 {
  padding-right: 1px !important;
}

.pr2 {
  padding-right: 2px !important;
}

.pr3 {
  padding-right: 3px !important;
}

.pr4 {
  padding-right: 4px !important;
}

.pr5 {
  padding-right: 5px !important;
}

.pr6 {
  padding-right: 6px !important;
}

.pr7 {
  padding-right: 7px !important;
}

.pr8 {
  padding-right: 8px !important;
}

.pr9 {
  padding-right: 9px !important;
}

.pr10 {
  padding-right: 10px !important;
}

.pr15 {
  padding-right: 15px !important;
}

.pr20 {
  padding-right: 20px !important;
}

.pr25 {
  padding-right: 25px !important;
}

.pr30 {
  padding-right: 30px !important;
}

.pr35 {
  padding-right: 35px !important;
}

.pr40 {
  padding-right: 40px !important;
}

.pr45 {
  padding-right: 45px !important;
}

.pr50 {
  padding-right: 50px !important;
}

.pr55 {
  padding-right: 55px !important;
}

.pr60 {
  padding-right: 60px !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.pb1 {
  padding-bottom: 1px !important;
}

.pb2 {
  padding-bottom: 2px !important;
}

.pb3 {
  padding-bottom: 3px !important;
}

.pb4 {
  padding-bottom: 4px !important;
}

.pb5 {
  padding-bottom: 5px !important;
}

.pb6 {
  padding-bottom: 6px !important;
}

.pb7 {
  padding-bottom: 7px !important;
}

.pb8 {
  padding-bottom: 8px !important;
}

.pb9 {
  padding-bottom: 9px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.pb15 {
  padding-bottom: 15px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.pb25 {
  padding-bottom: 25px !important;
}

.pb30 {
  padding-bottom: 30px !important;
}

.pb35 {
  padding-bottom: 35px !important;
}

.pb40 {
  padding-bottom: 40px !important;
}

.pb45 {
  padding-bottom: 45px !important;
}

.pb50 {
  padding-bottom: 50px !important;
}

.pb55 {
  padding-bottom: 55px !important;
}

.pb60 {
  padding-bottom: 60px !important;
}

.pl0 {
  padding-left: 0 !important;
}

.pl1 {
  padding-left: 1px !important;
}

.pl2 {
  padding-left: 2px !important;
}

.pl3 {
  padding-left: 3px !important;
}

.pl4 {
  padding-left: 4px !important;
}

.pl5 {
  padding-left: 5px !important;
}

.pl6 {
  padding-left: 6px !important;
}

.pl7 {
  padding-left: 7px !important;
}

.pl8 {
  padding-left: 8px !important;
}

.pl9 {
  padding-left: 9px !important;
}

.pl10 {
  padding-left: 10px !important;
}

.pl15 {
  padding-left: 15px !important;
}

.pl20 {
  padding-left: 20px !important;
}

.pl25 {
  padding-left: 25px !important;
}

.pl30 {
  padding-left: 30px !important;
}

.pl35 {
  padding-left: 35px !important;
}

.pl40 {
  padding-left: 40px !important;
}

.pl45 {
  padding-left: 45px !important;
}

.pl50 {
  padding-left: 50px !important;
}

.pl55 {
  padding-left: 55px !important;
}

.pl60 {
  padding-left: 60px !important;
}
/* width
========================================================================== */
.w50 {
  width: 50px !important;
}
.w60 {
  width: 50px !important;
}
.w70 {
  width: 70px !important;
}
.w80 {
  width: 80px !important;
}
.w90 {
  width: 90px !important;
}
.w100 {
  width: 100px !important;
}
.w110 {
  width: 110px !important;
}
.w120 {
  width: 120px !important;
}
.w130 {
  width: 130px !important;
}
.w140 {
  width: 140px !important;
}
.w150 {
  width: 150px !important;
}
.w160 {
  width: 160px !important;
}
.w170 {
  width: 170px !important;
}
.w180 {
  width: 180px !important;
}
.w190 {
  width: 190px !important;
}
.w200 {
  width: 200px !important;
}
.w210 {
  width: 210px !important;
}
.w220 {
  width: 220px !important;
}
.w300 {
  width: 300px !important;
}
.w380 {
  width: 380px !important;
}
.w420 {
  width: 420px !important;
}
.w460 {
  width: 460px !important;
}
.w25-per {
  margin-right: auto;
  margin-left: auto;
  width: 25% !important;
}
.w25-per {
  margin-right: auto;
  margin-left: auto;
  width: 25% !important;
}
.w33-per {
  margin-right: auto;
  margin-left: auto;
  width: 33.333% !important;
}
.w40-per {
  margin-right: auto;
  margin-left: auto;
  width: 40% !important;
}
.w45-per {
  margin-right: auto;
  margin-left: auto;
  width: 45% !important;
}
.w50-per {
  margin-right: auto;
  margin-left: auto;
  width: 50% !important;
}
.w55-per {
  margin-right: auto;
  margin-left: auto;
  width: 55% !important;
}
.w60-per {
  margin-right: auto;
  margin-left: auto;
  width: 60% !important;
}
.w65-per {
  margin-right: auto;
  margin-left: auto;
  width: 65% !important;
}
.w70-per {
  margin-right: auto;
  margin-left: auto;
  width: 70% !important;
}
.w75-per {
  margin-right: auto;
  margin-left: auto;
  width: 75% !important;
}
.w80-per {
  margin-right: auto;
  margin-left: auto;
  width: 80% !important;
}
.w85-per {
  margin-right: auto;
  margin-left: auto;
  width: 85% !important;
}
.w90-per {
  margin-right: auto;
  margin-left: auto;
  width: 90% !important;
}
.w95-per {
  margin-right: auto;
  margin-left: auto;
  width: 95% !important;
}
.w100-per {
  margin-right: auto;
  margin-left: auto;
  width: 100% !important;
}
.w-justify {
  flex: 1;
}
