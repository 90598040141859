/**
 * Block Style
**/
.bg-gray {
  background-color: $color-gray04 !important;
}
.bg-yellow {
  background-color: #fbfaf1 !important;
}
.bg-blue {
  background-color: #eff6fc !important;
}
.js-toggle,
.js-popup-button {
  cursor: pointer;
}
/**=========================================
* m-anchor-content
* ページ内リンク位置調整（ヘッダーのfixed被り対策）
=========================================*/
.m-anchor-content {
  display: block;
  margin-top: -48px;
  padding-top: 48px;
  @include media-query-sp {
    margin-top: -54px;
    padding-top: 54px;
  }
}
/**=========================================
* scrollbarデフォルト
* chrome,safari
=========================================*/
.scroll-wide-content {
  overflow-y: hidden;
}
//スタイル調整
::-webkit-scrollbar {
  overflow: visible;
  width: 10px;
  height: 10px;
  background-color: $color-gray04;
}
:-webkit-scrollbar-button {
  background: transparent;
  height: 0;
  width: 0;
}
::-webkit-scrollbar-thumb {
  background-color: #ddd;
  background-clip: padding-box;
  border: solid transparent;
  border-width: 1px 1px 1px 1px;
  min-height: 10px;
  border-radius: 10px;
  padding: 100px 0 0;
  box-shadow: none;
}
::-webkit-scrollbar-track {
  background-clip: padding-box;
  border: solid transparent;
  border-width: 0 0 0 1px;
}
body{
  &::-webkit-scrollbar-thumb {
    border-width: 1px 1px 1px 2px;
  }
  &::-webkit-scrollbar-track {
    border: solid $color-gray01;
    border-width: 0 0 0 1px;
  }
}
/**=========================================
* buttons
=========================================*/
.m-button-container {
  overflow: hidden;
  padding: 30px 35px 0;
}
.m-button-row {
  display: flex;
  margin: 0 -3px;
}
.m-button-col {
  display: flex;
  padding: 0 3px;
  &.w-auto {
    flex: 1;
  }
  > .inner {
    background-color: $color-gray04;
    padding: 10px 5px;
    width: 100%;
  }
}
.m-button {
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid $color-gray01;
  margin: 0 5px;
  padding: 12px 30px;
  font-size: 1.4rem;
  line-height: 1;
  font-weight: bold;
  transition: background-color .2s;
  &:hover {
    background-color: $color-gray04;
  }
  &.small {
    padding: 8px 20px;
    font-size: 1.3rem;
  }
  &.current {
    cursor: default;
    color: $key-color;
    &:hover {
      background-color: #fff;
    }
    .icon {
      color: $key-color;
    }
  }
  &.red {
    background-color: $key-color;
    border-color: transparent;
    color: #fff;
    &:hover {
      background-color: rgba($key-color,0.7);//fd8378
    }
    .icon {
      color: #fff;
    }
  }
  &.red-border {
    border: 1px solid $key-color;
    background-color: #fff;
    color: $key-color;
    &:hover {
      background-color: rgba($key-color,0.1);
    }
    .icon {
      color: $key-color;
    }
  }
  &.blue {
    border-color: transparent;
    background-color: $color-blue;
    color: #fff;
    &:hover {
      background-color: rgba($color-blue,0.7);//fd8378
    }
    .icon {
      color: #fff;
    }
  }
  .icon {
    display: flex;
    align-items: center;
    margin-right: 8px;
    height: 12px;
    font-size: 1.4rem;
    line-height: 1;
    color: #999;
    &-plus {
      margin-right: 5px;
      font-size: 1.2rem;
    }
    &-task {
      font-size: 1.6rem;
    }
    &-report {
      font-size: 1.6rem;
    }
  }
}
.m-button--tab-icon {
  @extend .m-button;
  margin: 0 3px;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  padding-bottom: 17px;
  min-width: 180px;
  &.current {
    margin-bottom: -1px;
  }
}
.m-button--radius {
  @extend .m-button;
  border-radius: 15px;
  padding: 8px 20px;
  font-size: 1.3rem;
  line-height: 1;
  font-weight: normal;
  .icon {
    margin-right: 5px;
    font-size: 1rem;
    &-pen {
      font-size: 1.7rem;
    }
    &-file01 {
      font-size: 1.5rem;
    }
    &-reset {
      font-size: 1.5rem;
    }
    &-report {
      font-size: 1.7rem;
    }
    &-site {
      font-size: 1.7rem;
    }
    &-download {
      font-size: 1.3rem;
    }
    &-comment {
      font-size: 1.5rem;
    }
  }
}
.m-button--border {
  @extend .m-button;
  cursor: pointer;
  border: 1px solid $key-color;
  background-color: #fff;
  color: $key-color;
  &:hover {
    background-color: rgba($key-color,0.1);
  }
  .icon {
    color: $key-color;
  }
  &.red {
    background-color: #fff;
    border-color: $key-color;
    color: $key-color;
    &:hover {
      background-color: rgba($key-color,0.1);
    }
    .icon {
      color: $key-color;
    }
  }
  &.blue {
    background-color: #fff;
    border-color: $color-blue;
    color: $color-blue;
    &:hover {
      background-color: rgba($color-blue,0.1);
    }
    .icon {
      color: $color-blue;
    }
  }
}
//アイコンのみのボタン
.m-icon-button {
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 100%;
    width: 1px;
    height: 1px;
    transition: .2s;
    transform-origin: center;
  }
  &:hover {
    &:before {
      background-color: #efefef;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  > .icon {
    position: relative;
    font-size: 1.5rem;
    color: #999;
    &-site {
      font-size: 1.6rem;
    }
  }
}
/**=========================================
* m-q-button
=========================================*/
.m-q-button {
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    border-radius: 100%;
    background-color: $color-gray03;
    transition: .2s;
    transform: scale(0);
  }
  &:hover,
  &.is-q-active {
    &:after {
      transform: scale(1);
    }
  }
  > .icon {
    position: relative;
    z-index: 2;
    font-size: 1.3rem;
    color: #999;
  }
}
.m-q-button-text {
  @extend .fukidashi-arrow--top;
  position: fixed;
  z-index: -1;
  display: inline-block;
  font-size: 1.2rem;
  line-height: 1;
  opacity: 0;
  transition: opacity .2s;
  &.is-q-active {
    z-index: 1;
    opacity: 1;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 12px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: #fff;
    border: 1px solid #919191;
  }
  &:after {
    position: absolute;
    top: 0px;
    left: 10px;
    border-bottom-color: #999;
  }
}
.m-q-button-body {
  position: relative;
  z-index: 2;
  display: inline-block;
  padding: 16px 5px 6px;
}
/**=========================================
* tooltip-text
=========================================*/
.js-tooltip {
  position: relative;
}
.js-tooltip-text {
  @extend .icon-arrow02;
  position: fixed;
  z-index: -1;
  display: inline-block;
  font-size: 1.2rem;
  line-height: 1;
  opacity: 0;
  transition: .2s;
  &.is-hover {
    z-index: 110;
    opacity: 1;
  }
  > span {
    position: relative;
    z-index: 2;
    display: inline-block;
    padding: 10px 6px 4px;
    line-height: 1.4;
    color: #fff;
    word-break: break-all;
  }
  &:before {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0;
    color: #999;
    font-size: 1rem;
    text-align: center;
    line-height: 1;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 7px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    border-radius: 3px;
    background-color: rgba(119, 119, 119, 0.8);
  }
}
/**=========================================
* .m-popup-content
=========================================*/
.m-popup-content {
  position: fixed;
  top: 50px;
  right: 0;
  z-index: -1;
  transition: .3s cubic-bezier(0,0,.2,1);
  transform: scale3d(1,1,1) translateY(-10px);
  opacity: 0;
  &.is-popup-active {
    z-index: 70;
    transform: scale3d(1,1,1) translateY(0px);
    opacity: 1;
  }
}
/**=========================================
* .m-modal-alert
=========================================*/
.m-alert-content {
  position: fixed;
  top: 50%;
  right: 0;
  left: 0;//gnav
  z-index: -1;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 0 200px;
  align-items: center;
  justify-content: center;
  transition: .2s;
  opacity: 0;
  &--task-alert {
    top: 0;
    height: 100vh;
    background: rgba(255,255,255,0);
    .m-alert-content-inner {
      position: absolute;
      top: 50%;
    }
  }
  &-inner {
    display: inline-block;
    background-color: rgba(153, 153, 153, 0.8);
    border-radius: 4px;
    box-shadow: 1px 3px 10px 0px rgba(0, 0, 0, 0.2);
    margin: 0 50px;
    padding: 40px;
    min-width: 360px;
    min-height: 180px;
  }
  &-text {
    margin: 0;
    text-align: center;
    font-size: 1.5rem;
    line-height: (24/15);
    color: #fff;
    font-weight: bold;
  }
  &.is-show {
    z-index: 100;
    opacity: 1;
  }
  .m-button,
  .m-button--border {
    padding-right: 10px;
    padding-left: 10px;
  }
  .m-button--border {
    &:hover {
      background-color: #fff;
      opacity: 0.7;
    }
  }
}
/**=========================================
* m-kanri-header
=========================================*/
.m-kanri-header {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  &-button {
    position: absolute;
    top: 0px;
    right: 0px;
  }
  &-icon-container {
    display: inline-flex;
    margin: -10px 0;
    padding-left: 20px;
    .m-title01 {
      margin: (13-15*(24/15-1)/2)+px 0;
    }
  }
  &-button-container {
    display: inline-flex;
    padding-left: 20px;
  }
}
/**=========================================
* m-tab content
* タブのようなデザイン
=========================================*/
.m-tab-nav {
  display: flex;
  margin: 30px 0 0;
}
.m-tab-body {
  background-color: #fff;
  border-top: 1px solid #dddddd;
}
/**=========================================
* m-select-media
* メディア選択のリスト
=========================================*/
.m-select-media-list {
  border: 1px solid $color-gray01;
  max-height: 450px;
  .mCSB_scrollTools {
    width: 11px;
  }
  .mCSB_scrollTools .mCSB_draggerContainer {
    border-left: 1px solid #ddd;
  }
}

.m-select-media-item {
  position: relative;
  display: block;
  background-color: #fff;
  border-bottom: 1px solid $color-gray01;
  padding: 0;
  width: 100%;
  opacity: 1;
  text-align: left;
  transition: opacity .2s;
  &.is-show {
  }
  &.is-media-hidden {
    display: none;
    opacity: 0;
  }
  &:last-child,
  &.last-child {
    border-bottom: none;
  }
  > .text {
    cursor: pointer;
    display: block;
    padding: 19px 60px 19px 20px;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1;
    &:hover {
      background-color: $color-gray04;
    }
  }
  > .m-icon-button {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -20px;
  }
}
/**=========================================
* m-list01
=========================================*/
.m-list01 {
  margin: 0;
  border-top: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
  padding: 0;
  > li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    min-height: 50px;
    &:nth-child(even)  {
      background-color: #f8f8f8;
    }
    .m-text03 {
      flex: 1;
    }
  }
}
/**=========================================
* news default style
=========================================*/
.m-news-container {
  @extend .bg-gray;
  padding-top: 30px;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 30px;
  .m-news-body {
    margin-top: 1px;
  }
}
.m-news-date {
  margin: 1px 0 0;
  width: 90px;
  font-size: 1.3rem;
  line-height: 1;
}
.m-news-body {
  flex: 1;
  h1 {
    margin: (20-15*0.5/2)+px 0 (-15*0.5/2)+px;
    font-size: 1.5rem;
    line-height: 1.5;
    &:first-child {
      margin-top: (-15*0.5/2)+px;
    }
  }
  h2 {
    margin: (20-14*0.5/2)+px 0 (-14*0.5/2)+px;
    font-size: 1.4rem;
    line-height: 1.5;
    &:first-child {
      margin-top: (-14*0.5/2)+px;
    }
  }
  p {
    margin: (5-14*(22/14-1)/2)+px 0 (-14*(22/14-1)/2)+px;
    font-size: 1.4rem;
    line-height: (22/14);
    &:first-child {
      margin-top: (-14*(22/14-1)/2)+px;
    }
  }
  a {
    color: $color-blue;
    &:hover {
      text-decoration: underline;
    }
  }
  ul,
  ol {
    margin: 5px 0 0 0;
    padding-left: 2.5rem;
    font-size: 1.4rem;
    line-height: (22/14);
    &:first-child {
      margin-top: 0px;
    }
  }
  hr {
    margin: 20px 0;
    border-top: 1px solid $color-gray02;
    border-right: none;
    border-bottom: none;
    border-left: none;
  }
}
/**=========================================
* modal window
=========================================*/
.js-modal-button {
  &:focus {
    outline-color: none;
    outline: none;
  }
}
.m-detail-content-body {
  overflow-y: auto;
  padding: 40px;
  height: 220px;
}
.m-detail-content-body--input {
  overflow-y: auto;
  background: #f8f8f8;
  padding: 20px;
  height: 220px;
  textarea {
    margin-left: 0 !important;
    height: 100%;
    width: 100%;
  }
}
.m-detail-content.seika {
  textarea {
    height: calc(100% - 20px);
  }
}
.error {
  .m-detail-content-body--input {
    background-color: #fdf3f2;
  }
}

.m-detail-content-inner {
  cursor: default;
  position: relative;
  z-index: 2;
  background: #fff;
  margin: 0 auto;
  border-radius: 4px;
  width: 500px;
  .mfp-close {
    cursor: pointer;
    display: block;
    position: absolute;
    top: -8px;
    right: -48px;
    line-height: 1;
    vertical-align: middle;
    text-align: center;
    color: #fff;
    opacity: 1;
    @include font-yugo;

    @include media-query-sp {
      top: -50px;
      right: 0px;
    }
    .icon:before {
      font-size: 3rem;
      @include media-query-sp {
        cursor: pointer;
        top: -40px;
        z-index: -1;
        font-size: 3.3rem;
      }
    }
  }
}
.m-detail-content-buttons {
  border-top: 1px solid #dddddd;
  padding: 20px 0;
  text-align: center;
}
/**=========================================
* magnific popup custom
=========================================*/
.mfp-container {
  padding: 10px;
}
.mfp-wrap {
  overflow: hidden;
  cursor: pointer !important;
}
//アニメーション用
.mfp-wrap {
  opacity: 0;
  transition: .2s;
  //出現時
  &.mfp-ready {
    opacity: 1;
  }
  //非表示時
  &.mfp-removing {
    opacity: 0;
  }
}
.mfp-bg {
  opacity: 0;
  transition: .2s;
}
.mfp-ready.mfp-bg {
  opacity: 0.8;
}
/**=========================================
* m-edit-button-container
=========================================*/
.m-edit-button-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: rgba(#fff,0.9);
  padding: 20px 0 20px 200px;
  text-align: center;
  transition: padding-left .2s ease-in-out;
}
.is-gnav-close {
  .m-edit-button-container {
    padding-left: 50px;
  }
}
