/**
 * Text Style
 */
/**=========================================
* テキスト設定
=========================================*/
.font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.color-keycolor {
  color: $key-color !important;
}
.color-red {
  color: $key-color !important;
}
.color-blue {
  color: $color-blue !important;
}
.color-white {
  color: #fff !important;
}
.color-default {
  color: $text-base-color !important;
}
.text-bold {
  font-weight: bold;
}
/**=========================================
* text-ellipsis, js-text-overflow
* 一行で溢れたらで三点リーダー挿入
=========================================*/
//一行
.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
//複数行（jsで操作）
.js-text-overflow {
  overflow: hidden;
}
/**=========================================
* m-lead
* リード文 14px, #444
=========================================*/
.m-lead {
  position: relative;
  margin: (30-14*(24/14-1)/2)+px 0 (-14*(24/14-1)/2)+px;
  padding: 0;
  font-size: 1.4rem;
  line-height: (24/14);
  color: #444;
  &:first-child {
    margin-top: (-14*(24/14-1)/2)+px;
  }
}
/**=========================================
* m-text01
* 本文テキスト　16px
=========================================*/
.m-text01 {
  position: relative;
  margin: (15-16*(24/16-1)/2)+px 0 (-16*(24/16-1)/2)+px;
  padding: 0;
  font-size: 1.6rem;
  line-height: (24/16);
  &:first-child {
    margin-top: (-16*(24/16-1)/2)+px;
  }
}
/**=========================================
* m-text02
* 本文テキスト　14px
=========================================*/
.m-text02 {
  position: relative;
  margin: (15-14*(24/14-1)/2)+px 0 (-14*(24/14-1)/2)+px;
  padding: 0;
  font-size: 1.4rem;
  line-height: (24/14);
  &:first-child {
    margin-top: (-14*(24/14-1)/2)+px;
  }
}
/**=========================================
* m-text03
* 本文テキスト　13px
=========================================*/
.m-text03 {
  position: relative;
  margin: (15-13*(24/13-1)/2)+px 0 (-13*(24/13-1)/2)+px;
  padding: 0;
  font-size: 1.3rem;
  line-height: (24/13);
  &:first-child {
    margin-top: (-13*(24/13-1)/2)+px;
  }
}
/**=========================================
* m-text-link
* 本文テキスト　13px
=========================================*/
.m-text-link {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
  .icon-blank {
    margin: 0 3px;
    &:before {
      font-size: 1.4rem;
    }
  }
}
/**=========================================
* m-error-text
* エラーテキスト　12px
=========================================*/
.m-error-text {
  margin: -3px 0 0;
  padding: 0 2px;
  width: 100%;
  font-size: 1.2rem;
  color: $color-error;
  text-align: left;
}
