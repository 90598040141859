@font-face {
  font-family: "iconfont";
  src: url('../fonts/iconfont.eot');
  src: url('../fonts/iconfont.eot?#iefix') format('eot'),
    url('../fonts/iconfont.woff2') format('woff2'),
    url('../fonts/iconfont.woff') format('woff'),
    url('../fonts/iconfont.ttf') format('truetype');
}

@mixin icon-styles {
  font-family: "iconfont";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

%icon {
  @include icon-styles;
}

@function icon-char($filename) {
  $char: "";

  @if $filename == address {
    $char: "\E001";
  }
  @if $filename == arrow01 {
    $char: "\E002";
  }
  @if $filename == arrow02 {
    $char: "\E003";
  }
  @if $filename == arrow03 {
    $char: "\E004";
  }
  @if $filename == arrow04 {
    $char: "\E005";
  }
  @if $filename == arrow05 {
    $char: "\E006";
  }
  @if $filename == balloon01 {
    $char: "\E007";
  }
  @if $filename == balloon02 {
    $char: "\E008";
  }
  @if $filename == bell {
    $char: "\E009";
  }
  @if $filename == calender {
    $char: "\E00A";
  }
  @if $filename == check01 {
    $char: "\E00B";
  }
  @if $filename == check02 {
    $char: "\E00C";
  }
  @if $filename == clip {
    $char: "\E00D";
  }
  @if $filename == close {
    $char: "\E00E";
  }
  @if $filename == comment {
    $char: "\E00F";
  }
  @if $filename == copy {
    $char: "\E010";
  }
  @if $filename == download {
    $char: "\E011";
  }
  @if $filename == file01 {
    $char: "\E012";
  }
  @if $filename == flag {
    $char: "\E013";
  }
  @if $filename == home {
    $char: "\E014";
  }
  @if $filename == link {
    $char: "\E015";
  }
  @if $filename == lobo {
    $char: "\E016";
  }
  @if $filename == login {
    $char: "\E017";
  }
  @if $filename == logout {
    $char: "\E018";
  }
  @if $filename == mail {
    $char: "\E019";
  }
  @if $filename == mc {
    $char: "\E01A";
  }
  @if $filename == note {
    $char: "\E01B";
  }
  @if $filename == note02 {
    $char: "\E01C";
  }
  @if $filename == order01 {
    $char: "\E01D";
  }
  @if $filename == order02 {
    $char: "\E01E";
  }
  @if $filename == pagetop {
    $char: "\E01F";
  }
  @if $filename == partner {
    $char: "\E020";
  }
  @if $filename == pen {
    $char: "\E021";
  }
  @if $filename == plus {
    $char: "\E022";
  }
  @if $filename == point {
    $char: "\E023";
  }
  @if $filename == question {
    $char: "\E024";
  }
  @if $filename == quote {
    $char: "\E025";
  }
  @if $filename == receive {
    $char: "\E026";
  }
  @if $filename == reply {
    $char: "\E027";
  }
  @if $filename == report {
    $char: "\E028";
  }
  @if $filename == reset {
    $char: "\E029";
  }
  @if $filename == search {
    $char: "\E02A";
  }
  @if $filename == setting {
    $char: "\E02B";
  }
  @if $filename == site {
    $char: "\E02C";
  }
  @if $filename == task {
    $char: "\E02D";
  }
  @if $filename == teikei {
    $char: "\E02E";
  }
  @if $filename == telmemo {
    $char: "\E02F";
  }
  @if $filename == time {
    $char: "\E030";
  }
  @if $filename == window {
    $char: "\E031";
  }
  @if $filename == window01 {
    $char: "\E032";
  }
  @if $filename == window02 {
    $char: "\E033";
  }
  @if $filename == window03 {
    $char: "\E034";
  }
  @if $filename == yen {
    $char: "\E035";
  }

  @return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
  &:#{$insert} {
    @if $extend {
      @extend %icon;
    } @else {
      @include icon-styles;
    }
    content: icon-char($filename);
  }
}

.icon-address {
  @include icon(address);
}
.icon-arrow01 {
  @include icon(arrow01);
}
.icon-arrow02 {
  @include icon(arrow02);
}
.icon-arrow03 {
  @include icon(arrow03);
}
.icon-arrow04 {
  @include icon(arrow04);
}
.icon-arrow05 {
  @include icon(arrow05);
}
.icon-balloon01 {
  @include icon(balloon01);
}
.icon-balloon02 {
  @include icon(balloon02);
}
.icon-bell {
  @include icon(bell);
}
.icon-calender {
  @include icon(calender);
}
.icon-check01 {
  @include icon(check01);
}
.icon-check02 {
  @include icon(check02);
}
.icon-clip {
  @include icon(clip);
}
.icon-close {
  @include icon(close);
}
.icon-comment {
  @include icon(comment);
}
.icon-copy {
  @include icon(copy);
}
.icon-download {
  @include icon(download);
}
.icon-file01 {
  @include icon(file01);
}
.icon-flag {
  @include icon(flag);
}
.icon-home {
  @include icon(home);
}
.icon-link {
  @include icon(link);
}
.icon-lobo {
  @include icon(lobo);
}
.icon-login {
  @include icon(login);
}
.icon-logout {
  @include icon(logout);
}
.icon-mail {
  @include icon(mail);
}
.icon-mc {
  @include icon(mc);
}
.icon-note {
  @include icon(note);
}
.icon-note02 {
  @include icon(note02);
}
.icon-order01 {
  @include icon(order01);
}
.icon-order02 {
  @include icon(order02);
}
.icon-pagetop {
  @include icon(pagetop);
}
.icon-partner {
  @include icon(partner);
}
.icon-pen {
  @include icon(pen);
}
.icon-plus {
  @include icon(plus);
}
.icon-point {
  @include icon(point);
}
.icon-question {
  @include icon(question);
}
.icon-quote {
  @include icon(quote);
}
.icon-receive {
  @include icon(receive);
}
.icon-reply {
  @include icon(reply);
}
.icon-report {
  @include icon(report);
}
.icon-reset {
  @include icon(reset);
}
.icon-search {
  @include icon(search);
}
.icon-setting {
  @include icon(setting);
}
.icon-site {
  @include icon(site);
}
.icon-task {
  @include icon(task);
}
.icon-teikei {
  @include icon(teikei);
}
.icon-telmemo {
  @include icon(telmemo);
}
.icon-time {
  @include icon(time);
}
.icon-window {
  @include icon(window);
}
.icon-window01 {
  @include icon(window01);
}
.icon-window02 {
  @include icon(window02);
}
.icon-window03 {
  @include icon(window03);
}
.icon-yen {
  @include icon(yen);
}
