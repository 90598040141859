/**
* Table Style
*/
/**=========================================
* task-container
=========================================*/
.task-container {
  padding-top: 20px;
  width: 100%;
  > .task-body {
    border-top: 1px solid $color-gray02;
    > .task-row {
      &:first-child {
        border-top: none;
      }
    }
  }
}
.task-complete {
  background-color: #fcfcfc;
  color: #bbbbbb;
  .js-datepicker input {
    color: #bbbbbb;
  }
  .task-row {
    &.level-1 {
      background-color: #f8f8f8;
    }
    &.level-2 {
      background-color: #f8f8f8;
    }
  }
}
.task-row {
  display: flex;
  width: 100%;
  border-top: 1px solid #dddddd;
  &.task-header {
    border-top: none;
  }
  &.task-footer {
    border-top: 1px solid #bbb;
  }
  &.level-1 {
    background-color: #fbfaf1;
  }
  &.level-2 {
    background-color: #eff6fc;
  }
  &.task-complete {
    background-color: #fcfcfc;
    &.level-1 {
      background-color: #f8f8f8;
    }
    &.level-2 {
      background-color: #f8f8f8;
    }
  }
  .level-icon {
    margin-right: 4px;
    width: 20px;//28
    height: 20px;
    .icon {
      font-size: 1rem;
      margin-left: 2px;
      transform: scale(0.9) rotate(-90deg);
      color: #999;
      transition: .2s;
    }
    &.is-toggle-button-active {
      .icon {
        margin-left: 0;
        transform: scale(0.9) rotate(0deg);
      }
    }
  }
  &.level-2 {
    .level-icon {
      margin-left: 16px;
    }
  }
  &.level-3 {
    .task-col--1 {
      .task-col-inner {
        padding-left: 48px;
      }
    }
  }
  &:not(.level-1) {
    &:not(.level-2) {
      &:not(.level-3) {
        .level-icon {
          display: none;
        }
        .task-col--1 {
          .task-col-inner {
            padding-left: 32px;
          }
        }
      }
    }
  }
}
.task-col {
  overflow: visible;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  font-size: 1.3rem;
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  word-break: break-all;
  &--1 {
    flex: 1;
    min-width: 297px;
    .task-col-inner {
      width: 100%;
    }
  }
  &--2 {
    width: 119px;
    .task-col-inner {
      width: 100%;
      text-align: center;
    }
  }
  &--3 {
    width: 76px;
  }
  &--4 {
    width: 108px;
  }
  &--5 {
    width: calc(260px/2);
    .task-col-inner {
      width: 100%;
      text-align: center;
    }
  }
  &--6 {
    width: 108px;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: $color-gray03;
    width: 1px;
  }
  &:first-child {
    &:before {
      display: none;
    }
  }
  .irai-icon {
    position: absolute;
    top: 50%;
    right: -6px;
    z-index: 5;
    margin-top: -5px;
    .icon {
      font-size: 1rem;
      transform: scale(0.9);
      color: #999;
    }
  }
  .m-button,
  .m-button--border,
  .m-button--radius {
    position: relative;
    margin: 0 auto;
    padding: 8px 15px 7px;
    font-size: 1.3rem;
    input[type="submit"]{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      opacity: 0;
    }
  }
  .m-form-select {
    text-align: left;
    font-size: 1.3rem;
    .minict_wrapper {
      span {
        font-size: 1.3rem;
          width: 100%;
      }
      ul {
        padding: 2px 0;
      }
      li {
        padding: 10px 10px;
        font-size: 1.3rem;
      }
    }
  }
  input[type="text"]{
    padding: 4px 5px 3px;
    text-align: center;
    width: 100%;
  }
  label.checkbox-text {
    padding-left: 18px;
  }
  .m-input-item--term {
    padding: 0;
  }
  .ui-datepicker-trigger,
  .m-input-calendar-reset {
    display: none;
  }
}
.task-header,
.task-footer {
  .task-col {
    line-height: 1;
    color: #444;
    font-weight: bold;
  }
  .task-col-inner {
    margin: 0 auto;
    padding: 20px 8px;
  }
}
.task-body {
  .task-col {
    text-align: left;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background-color: $color-gray03;
      width: 1px;
    }
    &:first-child {
      &:before {
        display: none;
      }
    }
    &.show-all-text {
      white-space: normal;
      text-overflow: inherit;
    }
    .time {
      margin-left: 2px;
      font-size: 1.2rem;
      color: #bbbbbb;
    }
  }
  .task-col-inner {
    margin: 0 auto;
    padding: 10px 8px;
  }
}
/**=========================================
* task-text_icon-block
* アイコン付きのセル
=========================================*/
.task-text_icon-block {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 50px;
  vertical-align: middle;
  .text {
    flex: 1;
  }
  .task-icon-button {
    display: flex;
    padding-left: 20px;
    .m-icon-button {
      margin: -10px 0;
    }
  }
}
