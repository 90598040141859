/**
* Form Style
*/
/**=========================================
* form layout
=========================================*/
.m-textbox-button-container {
  display: flex;
}
.m-form-button-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 3px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid transparent;
  padding: 3px 10px;
  &:first-child {
    margin-top: 0;
  }
  &--inline-block {
    display: inline-block;
    .m-input-item {
      display: inline-block;
    }
  }
}
.m-input-item {
  display: flex;
  align-items: center;
  padding: 0 10px;
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
  &--term {
    &:first-child {
      padding-right: 5px;
    }
    &:last-child {
      padding-left: 5px;
    }
  }
}
/**=========================================
* error
=========================================*/
.icon-required {
  color: $key-color;
  font-size: 1.4rem;
  line-height: 1;
}
.error-block {
  th,
  td {
    background-color: #fdf3f2;
    input {
      border-color: $key-color;
    }
    .minict_wrapper {
      border-color: $key-color;
    }
    .m-form-button-container {
      border-color: $key-color;
    }
  }
}
.error-text {
  display: block;
  margin: 5px 0 0 0;
  font-size: 1.2rem;
  color: $key-color;
}
/**=========================================
* input
=========================================*/
button {
  @include font-yugo;
  outline: none;
  border: none;
  color: $text-base-color;
  &:focus {
    outline: none;
  }
}
input {
  border: none;
  color: #777;
}
textarea {
  color: #777;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance:textfield;
}
input[type=button] {
  &:focus {
    outline-color: none;
    outline: none;
  }
}
input[type=submit] {
  @include font-yugo;
  cursor: pointer;
  outline: none;
  &:focus {
    outline: none;
  }
}
input[type=text],
input[type=number],
textarea {
  @include font-yugo;
  display: flex;
  align-items: center;
  margin: 0 10px;
  border-radius: 4px;
  border: 1px solid #dddddd;
  padding: 4px 10px 3px;
  width: calc(100% - 20px);
  min-height: 30px;
  font-size: 1.4rem;
  line-height: 1;
  -moz-appearance:textfield;

  &::placeholder {
    color: #bbbbbb;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  &:focus {
    outline-color: none;
    outline: none;
  }
  &:first-child {
    margin-left: 0;
    width: calc(100% - 10px);
  }
  &:last-child {
    margin-right: 0;
    width: calc(100% - 10px);
  }
  &:first-child:last-child {
    margin-right: 0;
    width: 100%;
  }
}
.error {
  input[type=text],
  input[type=number],
  textarea {
    border-color: $color-error;
  }
}
textarea {
  min-height: 110px;
  line-height: (22/14);
  resize: vertical;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=reset] {
  box-sizing : border-box;
  background: none;
  outline: none;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  color: #777;
}
.is-safari form {
  input[type=text] {
    &::placeholder {
      line-height: 1.4;
    }
  }
}
/**=========================================
* ファイルアップロード
=========================================*/
label.m-input-file {
  cursor:pointer;
  display: block;
  font-weight: bold;
  input[type="file"] {
    display: none;
  }
  .m-textbox-button-container {
    position: relative;
  }
  .w-justify {
    position: relative;
  }
  .m-input-file-button {
    cursor:pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
  }
}
/**=========================================
* label付きボタン
=========================================*/
label {
  &.radio-text,
  &.checkbox-text {
    position      : relative;
    z-index: 5;
    cursor        : pointer;
    display       : inline-block;
    overflow      : hidden;
    box-sizing    : border-box;
  }
  &.checkbox-text {
    padding-left : calc(20px + 5px);
    line-height  : 20px;
  }

  /** before after **/
  &.checkbox-text:before,
  &.checkbox-text:after {
    content  : '';
    position : absolute;
    box-sizing : border-box;
  }
  &.checkbox-text {
    padding-left : calc(18px + 9px);
    font-size: 1.4rem;
    line-height: 1.6;

    .input-icon {
      cursor: pointer;
      display: inline-block;
      @extend .icon-check02;
      &:after {
        content  : '';
        position : absolute;
        box-sizing : border-box;
        top              : 0px;
        bottom           : 0px;
        margin-top       : auto;
        margin-bottom    : auto;
      }
      //外側の箱
      &:after {
        z-index: 2;
        border-radius    : 4px;
        width         : 18px;
        height        : 18px;
        background-color: #fff;
        border        : 1px solid $color-gray01;
        left          : 0px;
        z-index       : 1;
      }
      //check
      &:before {
        position: absolute;
        top: 50%;
        left: 2px;
        z-index: 5;
        margin-top: -7px;
        font-size: 1.4rem;
        line-height: 1;
        color: $key-color;
        opacity: 0;
        transition: .2s;
        transform: scale3d(0,0,0);
      }
    }
  }
  &.radio-text {
    padding-left : calc(18px + 9px);
    font-size: 1.4rem;
    line-height: 1.6;

    .input-icon {
      cursor: pointer;
      display: inline-block;
      &:before,
      &:after {
        content  : '';
        position : absolute;
        box-sizing : border-box;
        top              : 0px;
        bottom           : 0px;
        margin-top       : auto;
        margin-bottom    : auto;
      }
      //外側の丸
      &:before {
        border-radius    : 100%;
        width         : 18px;
        height        : 18px;
        background-color: #fff;
        border        : 1px solid $color-gray01;
        left          : 0px;
        z-index       : 1;
      }
      //内側の丸
      &:after {
        z-index       : 2;
        width            : 8px;
        height           : 8px;
        border-radius    : 100%;
        //left             : calc(((18px/2) - (10px) / 2));
        left: 5px;
        background-color : $key-color;
        opacity: 0;
        transition: .2s;
        transform: scale3d(0,0,0);
      }
    }
  }
}
.error {
  label.checkbox-text {
    .input-icon {
      &:after {
        border-color: $color-error;
      }
    }
  }
  label.radio-text {
    .input-icon {
      &:before {
        border-color: $color-error;
      }
    }
  }
}
/** checkbox input **/
input[type="checkbox"] {
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  position   : absolute;
  top           : 0px;
  bottom        : 0px;
  left: 0;
  z-index    : 2;
  margin     : auto 0px;
  box-sizing    : border-box;
  outline       : none;
  width      : 18px;
  height     : 18px;
  &:checked {
    box-shadow : none;
    ~ .input-icon:before {
      opacity: 1;
      transform: scale3d(1,1,1);
    }
  }
  &:focus{
    background: none;
    opacity: 0.2;
  }
  &:focus {
    box-shadow : none;
  }
}

/** radio input **/
input[type="radio"] {
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  position   : absolute;
  top           : 0px;
  bottom        : 0px;
  left: 0;
  z-index    : 2;
  margin     : auto 0px;
  box-sizing    : border-box;
  outline       : none;
  border-radius : 100%;
  width      : 18px;
  height     : 18px;
  &:checked {
    box-shadow : none;
    ~ .input-icon:after {
      opacity: 1;
      transform: scale3d(1,1,1);
    }
  }
  &:focus{
    background: none;
    opacity: 0.2;
  }
  &:focus {
    box-shadow : none;
  }
}

/**=========================================
* プルダウン
=========================================*/
select {
  @include font-yugo;
  appearance: none;
  background: transparent;
  border-radius: 4px;
  border: 1px solid #dddddd;
  padding: 6px 30px 6px 9px;
  font-size: 1.2rem;
  line-height: 1;
  color: #777;
  &:focus {
    outline: none;
  }
  &::-ms-expand {
    display: none;
  }
  &:disabled {
    border: 1px solid #eee;
  }
}
.minict_wrapper span.color-default:empty:before {
  color: $text-base-color !important;
}
.minict_wrapper span::placeholder,
.minict_wrapper span:before {
  color: #777 !important;
}
.m-form-select {
  width: 100%;
}
/**=========================================
* カレンダー
=========================================*/
.m-input-calendar {
  position: relative;
  input[type=text] {
    cursor: pointer;
    width: 100%;
  }
  //消しゴム（ボタン透過）
  &-reset {
    cursor: pointer;
    position: absolute;
    top: 0;
    display: flex;
    right: 4px;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    z-index: 10;
    &:hover {
      .icon-reset {
        color: $key-color;
      }
    }
  }
  //消しゴムアイコン
  .icon-reset {
    position: relative;
    top: 1px;
    z-index: 1;
    font-size: 1.7rem;
    color: #999;
    transition: .2s;
  }
  //カレンダーアイコン（ボタン）
  .ui-datepicker-trigger {
    box-sizing : border-box;
    background: none;
    outline: none;
    cursor: pointer;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: none;
    position: absolute;
    top: 50%;
    right: 36px;
    margin-top: -9px;
    padding: 0;
    font-size: 1.6rem;
    line-height: 1;
    color: #999;
    transition: .2s;
    &:hover {
      color: $key-color;
    }
  }
}
.js-datepicker {
  &:hover {
    .icon-calender {
      color: $key-color;
    }
  }
}
//
#ui-datepicker-div.ui-datepicker {
  z-index: 100 !important;
  background: #fff;
  box-shadow: 1px 3px 10px 0px rgba(0, 0, 0, 0.2);
  border: none;
  padding: 30px;
  width: 277px;
  * {
    @include font-yugo;
  }
  .ui-icon,
  .ui-widget-content .ui-icon {
  	background-image: none;
  }
  .ui-widget-header .ui-icon {
  	background-image: none;
  }
  .ui-state-hover .ui-icon,
  .ui-state-focus .ui-icon,
  .ui-button:hover .ui-icon,
  .ui-button:focus .ui-icon {
  	background-image: none;
  }
  .ui-state-active .ui-icon,
  .ui-button:active .ui-icon {
  	background-image: none;
  }
  .ui-state-highlight .ui-icon,
  .ui-button .ui-state-highlight.ui-icon {
  	background-image: none;
  }
  .ui-state-error .ui-icon,
  .ui-state-error-text .ui-icon {
  	background-image: none;
  }
  .ui-button .ui-icon {
  	background-image: none;
  }
  .ui-datepicker-calendar {
    margin: 27px 0 0 0;
    padding: 7px 0;
  }
  thead {
    th {
      padding: 0;
      height: 26px;
      width: 26px;
      text-align: center;
      vertical-align: middle;
      font-size: 1rem;
      color: #bbbbbb;
    }
  }
  //タイトル
  .ui-widget-header {
    background: none;
    border: none;
    padding: 0;
  }
 .ui-datepicker-title {
   display: flex;
    margin: 0 60px 0 0;
    color: $text-base-color;
    text-align: left;
    font-weight: normal;
  }
  .ui-datepicker-title select {
    font-size: 1.4rem;
  }
  //月送りの矢印
  .ui-datepicker-prev,
  .ui-datepicker-next {
    cursor: pointer;
    border-radius: 100%;
    border: none;
    top: 0;
    width: 26px;
    height: 26px;
    text-align: center;
    transition: background-color .2s;
    &:hover {
      background-color: #f8f8f8;
    }
    @extend .icon-arrow01;
    &:before {
      display: inline-block;
      font-size: 1rem;
      color: #999;
    }
  }
  .ui-datepicker-prev {
    left: inherit;
    right: 26px;
    &:before {
      transform: rotate(90deg);
    }
  }
  .ui-datepicker-next {
    left: inherit;
    right: 0;
    &:before {
      transform: rotate(-90deg);
    }
  }
  .ui-datepicker-prev-hover,
  .ui-datepicker-next-hover {
    top: 0;
  }
  //年
  .ui-datepicker-year {
    + .minict_wrapper {
      width: 86px;
    }
  }
  select.ui-datepicker-year {
    cursor: pointer;
    position: relative;
    z-index: 2;
    width: 83px;
  }
  .select-arrow-year {
    position: absolute;
    top: 50%;
    left: 62px;
    margin-top: -5px;
    z-index: 1;
    font-size: 1rem;
    color: #999;
  }
  //月
  .ui-datepicker-month {
    + .minict_wrapper {
      margin-left: 5px;
      width: 70px;
    }
  }
  select.ui-datepicker-month {
    cursor: pointer;
    position: relative;
    z-index: 2;
    margin-left: 5px;
    width: 70px;
  }
  .select-arrow-month {
    position: absolute;
    top: 50%;
    left: 138px;
    margin-top: -5px;
    z-index: 1;
    font-size: 1rem;
    color: #999;
  }
  //日付セル
  .ui-state-default,
  .ui-widget-content .ui-state-default,
  .ui-widget-header .ui-state-default,
  .ui-button,
  .ui-button.ui-state-disabled:hover,
  .ui-button.ui-state-disabled:active {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: transparent;
    margin: 0 auto;
    border-radius: 100%;
    height: 26px;
    width: 26px;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1;
    color: #444;
    transition: background-color .2s, color .2s;
    &:hover {
      background-color: $key-color;
      color: #fff;
    }
  }
  .ui-state-active,
  .ui-widget-content .ui-state-active,
  .ui-widget-header .ui-state-active,
  a.ui-button:active,
  .ui-button:active,
  .ui-button.ui-state-active:hover {
    background-color: $key-color;
    color: #fff;
  }
  td {
    border-radius: 100%;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1;
    color: #444;
    transition: background-color .2s, color .2s;
  }
  .ui-state-highlight {
    background-color: #f8f8f8;
  }
}
