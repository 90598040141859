//breakpoints
$breakpoints-spfirst: (
  'xs': 'screen and (min-width: 480px)',
  'sm': 'screen and (min-width: 768px)',
  'md': 'screen and (min-width: 992px)',
  'lg': 'screen and (min-width: 1240px)',
) !default;
$breakpoints-pcfirst: (
  'xs': 'screen and (max-width: 479px)',
  'sm': 'screen and (max-width: 767px)',
  'md': 'screen and (max-width: 991px)',
  'lg': 'screen and (max-width: 1239px)',
) !default;

// color
$key-color: #fc4f3f;
$sub-color: #ff6600;
$text-base-color: #777;
$text-sub-color: #444;
$link-base-color: #777;
$link-hover-color: #777;

$color-error: #dc1503;
$color-red: #dc1503;
$color-blue: #62b0e2;
$color-gray01: #dddddd;
$color-gray02: #bbbbbb;
$color-gray03: #efefef;
$color-gray04: #f8f8f8;

//transition
//transition: .2s ease-in-out;
