/**
 * Icon Style
**/
/**=========================================
* icon
=========================================*/
.icon {
  display: inline-block;
  line-height: 1;
  font-size: 1.6rem;
}
.js-toggle {
  &.is-toggle-button-active {
    .icon-arrow01 {
      //transform: rotate(180deg);
    }
  }
}

/**=========================================
* fukidashi arrow
* @extendとして使用
* absolute,colorは各所で設定
=========================================*/
.fukidashi-arrow--left {
  &:after {
    content: "";
    border-top: 5px solid transparent;
    border-right: 6px solid #fff;
    border-bottom: 5px solid transparent;
    border-left: 6px solid transparent;
    width: 0;
    height: 0;
  }
}
.fukidashi-arrow--top {
  &:after {
    content: "";
    border-top: 6px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 5px solid transparent;
    width: 0;
    height: 0;
  }
}
