/**
 * Footer Style
**/
.copyright {
  display: block;
  padding: 10px 0;
  font-size: 1.2rem;
  line-height: 1;
  color: $color-gray02;
}
.pagetop {
  position: fixed;
  right: 15px;
  bottom: 0;
  z-index: 50;
  background-color: rgba(45, 62, 80, 0.5);
  border-radius: 4px 4px 0 0;
  padding: 12px 0 0;
  width: 40px;
  height: 40px;
  color: #fff;
  text-align: center;
  line-height: (18/16);
  transition: height 0.2s ease-out;
  &:hover {
    height: 50px;
  }
  .icon {
    font-size: 1.8rem;
    color: #fff;
  }
}
