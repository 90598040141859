/**
 * Page Communication Style
**/
/**=========================================
* commu-row
=========================================*/
.commu-row {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	.m-commu-center--between{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.m-commu-center--start{
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
	.m-commu-center--center{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.m-commu-start--start{
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
	}
	.m-commu-end--between{
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
	}
}
/**=========================================
* commu-col
=========================================*/
.commu-col {
	.icon-search{
		color: #999999;
		background: none;
	}
}

/**=========================================
* commu-account
=========================================*/
.commu-account{
	width: 230px; min-height: 100vh;
	background-color: #f8f8f8;
	border-right: 1px solid #dddddd;
	> .account-search{
		position: relative;
		padding: 10px;
		background-color: #f8f8f8;
		border-bottom: 1px solid #efefef;
		form{
			.commu-form{
				width: 168px;
			}
			> input[type="text"]{
				border: 1px solid #dddddd;
				border-radius: 4px;
				font-size: 1.4rem;
				padding: 6px 10px 8px 10px;
				max-width: 100%;
				box-sizing: border-box;
			}
		}
	}
	> .account-list{
		.account-block {
			display: flex;
			align-items: center;
			position: relative;
			border-left: 1px solid #efefef;
			padding: 10px 10px 8px 10px;
			transition: background-color .2s;
			cursor: pointer;
			&--member {
				min-width: 229px;
			}
			&--active{
				background-color: #dddddd;
			}
			&:hover {
				background-color: #dddddd;
			}
			> .text {
				display: inline-block;
				margin-left: 10px;
				font-size: 1.4rem;
				font-weight: bold;
				&:first-child {
					margin-left: 0;
				}
			}
			> .member-icon {
				display: block;
				overflow: hidden;
				border-radius: 100%;
				width: 32px;
				height: 32px;
			}
		}
		.mCSB_scrollTools .mCSB_draggerContainer{
			background-color: #f8f8f8;
		}
	}
}

.commu-right-area{
	position: absolute;
  	top: 0; left: 230px; right: 0;
}

/**=========================================
* commu-timeline
=========================================*/
.commu-timeline{
	position: absolute;
	top: 0;
	right: 361px;
	left: 0;
	display: flex;
	flex-flow: column;
	height: 100vh;
	min-width: 400px;
	> .timeline-head{
		padding: 3px 10px 10px 10px;
		width: 100%;
		border-bottom: 1px solid #dddddd;
		.account-search{
			form{
				.commu-form{
					width: 180px;
				}
				> input[type="text"]{
					border: 1px solid #dddddd;
					border-radius: 4px;
					font-size: 1.4rem;
					padding: 6px 10px 8px 10px;
					max-width: 100%;
					box-sizing: border-box;
				}
 
				.commu-checkbox{
					display: table;
					padding: 0px 20px;
					border-left: 1px solid #efefef;
					li{
						display: table-cell;
						padding-right: 20px;
						white-space: nowrap;
					}
				}
			}
		}
		.account-block {
			display: flex;
			align-items: center;
			position: relative;
			padding: 10px 10px 0px 10px;
			&--member {
				min-width: 229px;
			}
			> .text {
				display: inline-block;
				margin-left: 10px;
				font-size: 1.4rem;
				font-weight: bold;
				&:first-child {
					margin-left: 0;
				}
			}
			> .member-icon {
				display: block;
				overflow: hidden;
				border-radius: 100%;
				width: 25px;
				height: 25px;
			}
		}
	}
	> .timeline-body{
    	overflow-x: hidden;
		position: relative;
		height: 80vh;
		.timeline-data-head{
			position: relative;
			margin-top: 16px;
			border-top: 1px solid #efefef;
			> .timeline-data-container{
				position: absolute;
				top: -10px;
				width: 100%;
				text-align: center;
				line-height: 1;
				> .data{
					display: inline-flex;
					align-items: center;
					justify-content: center;
					padding: 0 5px;
					background-color: #fff;
					.icon{
						padding: 3px 5px 0 0;
						color: #777777;
						font-size: 1.2rem;
					}
					.taxt {
						color: #777777;
						font-size: 1.2rem;
					}
				}
			}
		}
		.timeline-message{
			position: relative;
			border-top: 1px solid transparent;
			border-bottom: 1px solid transparent;
			&--border{
				border-top: 1px solid #efefef;
			}
			&--end{
				height: 24px;
			}
			.timeline-post{
				position: relative;
				padding-right: 10px;
				&--mail{
					border: 1px solid #eea900;
					.icon {
						display: block;
						overflow: hidden;
						padding-top: 7px;
						float: left;
						width: 29px; height: 29px;
						background: #eea900;
						color: #ffffff;
						text-align: center;
					}
					&:hover .post-hover{
						display: flex;
					}
					.post-hover{
						display: none;
						position: absolute;
						bottom: -16px; right: -1px;
						margin: 0;
						padding: 0px 20px 8px 20px;
						background-color: #ffffff;
						border-right: 1px solid #eea900;
						border-left: 1px solid #eea900;
						border-bottom: 1px solid #eea900;
						border-bottom-left-radius : 5px;
						border-bottom-right-radius : 5px;
						list-style: none;
						z-index: 2;
						.list{
							margin-left: 20px;
							font-weight: bold;
							cursor: pointer;
							&:hover{
								.icon{
									color: #fc594a;
								}
								.taxt{
									color: #fc594a;
								}
							}
							.icon{
								padding-right: 10px;
								padding-top: 0px;
								float: none;
								width: auto;
								height: auto;
								background: none;
								color: #999999;
								font-size: 1.2rem;
							}
							.taxt{
								color: #999999;
								font-size: 1.2rem;
							}
							&:first-child{
								margin-left: 0px;
							}
						}
					}
				}
				&--memo{
					border: 1px solid #62b0e2;
					.icon {
						display: block;
						overflow: hidden;
						padding-top: 7px;
						float: left;
						width: 29px; height: 29px;
						background: #62b0e2;
						color: #ffffff;
						text-align: center;
						&-telmemo {
							position: absolute;
							
						}

					}
					&:hover .post-hover{
						display: flex;
					}

					.post-hover{
						display: none;
						position: absolute;
						bottom: -16px; right: -1px;
						margin: 0;
						padding: 0px 20px 8px 20px;
						background-color: #ffffff;
						border-right: 1px solid #62b0e2;
						border-left: 1px solid #62b0e2;
						border-bottom: 1px solid #62b0e2;
						border-bottom-left-radius : 5px;
						border-bottom-right-radius : 5px;
						list-style: none;
						z-index: 2;
						.list{
							margin-left: 20px;
							font-weight: bold;
							cursor: pointer;
							&:hover{
								.icon{
									color: #fc594a;
								}
								.taxt{
									color: #fc594a;
								}
							}
							.icon{
								padding-right: 10px;
								padding-top: 0px;
								float: none;
								width: auto;
								height: auto;
								background: none;
								color: #999999;
								font-size: 1.2rem;
							}
							.icon-arrow01{
								padding-left: 10px;
								padding-right: 0;
								font-size: 1rem;
								padding-top: 3px;
							}
							.taxt{
								color: #999999;
								font-size: 1.2rem;
							}
							&:first-child{
								margin-left: 0px;
							}
						}
					}

					.text-post{
						max-height: 120px;
						overflow-y: hidden;
					}
				}
				.text-post{
					margin-top: 10px;
					margin-bottom: 20px;
				}
				.carbon{
					margin-bottom: 8px;
					margin-right: 30px;
					color: #bbbbbb;
				}
				.title{
					margin: 0;
					margin: 5px 0;
					white-space: pre-wrap;
					word-wrap: break-word;
					line-height: (23/13);
					font-weight: normal;
				}
			}
			.message-body{
				margin: 10px 0;
			}
			.timeline-post-body{
				padding: 20px 30px 20px 20px;
				.member-icon {
					display: block;
					overflow: hidden;
					border-radius: 100%;
					width: 32px; height: 32px;
					float: left;
				}
				.text {
					position: relative;
					padding-left: 40px;
					zoom: 1;
					font-size: 1.4rem;
					.text-width{
						width: 80%;
					}
					.name{
						margin: 0;
						margin-bottom: 8px;
						margin-right: 18px;
						font-size: 1.4rem;
						white-space: nowrap;
					}
					.data{
						position: absolute;
						top: 0; right: 0;
						font-size: 1.2rem;
					}
					.m-text-lv01{
						margin: 0;
						white-space: pre-wrap;
						word-wrap: break-word;
						line-height: (23/13);
					}
				}
			}
			.member{
				position: relative;
				padding: 10px 30px 10px 20px;
				border-top: 1px solid transparent;
				border-bottom: 1px solid transparent;
				&:hover{
					border-top: 1px solid #fda79f;
					border-bottom: 1px solid #fda79f;
					background: #fff3f2;
				}
				.member-icon {
					display: block;
					overflow: hidden;
					border-radius: 100%;
					width: 32px; height: 32px;
					float: left;
				}
				.text {
					position: relative;
					padding-left: 40px;
					padding-right: 10px;
					zoom: 1;
					font-size: 1.4rem;
					.text-width{
						width: 80%;
					}
					.name{
						margin: 0;
						margin-bottom: 8px;
						margin-right: 18px;
						font-size: 1.4rem;
						white-space: nowrap;
					}
					.data{
						position: absolute;
						top: 0; right: 0;
						font-size: 1.2rem;
					}
					.m-text-lv01{
						margin: 0;
						white-space: pre-wrap;
						word-wrap: break-word;
						line-height: (23/13);
					}
				}
				&:hover{
					.message-hover {
						display: flex;
					}
				}
				& {
					.message-hover {
						display: none;
					}
				}
				.message-hover{
					position: absolute;
					bottom: -31px; right: 30px;
					margin: 0;
					padding: 2px 20px 10px 20px;
					background-color: #fff3f2;
					border-right: 1px solid #fda79f;
					border-left: 1px solid #fda79f;
					border-bottom: 1px solid #fda79f;
					border-bottom-left-radius : 5px;
					border-bottom-right-radius : 5px;
					list-style: none;
					z-index: 2;
					.list{
						margin-left: 20px;
						font-weight: bold;
						cursor: pointer;
						&:hover{
							.icon{
								color: #fc594a;
							}
							.taxt{
								color: #fc594a;
							}
						}
						.icon{
							padding-right: 10px;
							color: #999999;
							font-size: 1.2rem;
						}
						.taxt{
							color: #999999;
							font-size: 1.2rem;
						}
						&:first-child{
							margin-left: 0px;
						}
					}

				}
			}
		}
		.timeline-message--post + .timeline-message--post .timeline-post-body{
			padding: 0px 30px 20px 20px;
		}
	}
}


/**=========================================
* timeline-inpu
=========================================*/
.timeline-input{
	padding-left: 10px;
	border-top: 1px solid #dddddd;
    background-color: #fcfcfc;
	z-index: 1;

	.timeline-tab{
		display: flex;
		overflow: hidden;
		flex-flow: row;
		input[type="radio"]{
			display:none;
		}
		.tab-area{
			width: 66px;
			z-index: 2;
			label{
				display:block;
				padding: 11px 0 17px 0;
				width:67px;
				max-height: 65px;
				text-align:center;
				font-size:13px;
				border-left: 1px solid #dddddd;
				border-bottom: 1px solid #dddddd;
				border-top-left-radius : 5px;
				border-bottom-left-radius : 5px;
				cursor:pointer;
				.title-h2{
					margin: 0;
					font-size: 1.2rem;
				}
			}
			.tab1-label{
				margin-bottom: 5px;
				border-right: 1px solid #dddddd;
				background-color:#fff3f2;
				transition: .2s;
				&:hover{
					.icon{
						opacity: .7;
					}
					.title-h2{
						opacity: .7;
					}
				}
				.icon{
					color: #fc4f3f;
				}
			}
			.tab2-label{
				border-top: 1px solid #dddddd;
				border-right: 1px solid #dddddd;
				background-color:#fbfaf1;
				transition: .2s;
				&:hover{
					.icon{
						opacity: .7;
					}
					.title-h2{
						opacity: .7;
					}
				}
				.icon{
					color: #eea900;
				}
			}
		}
		.panel-area{
			width: 100%;
			min-height: 139px;
			border-left: 1px solid #dddddd;
			z-index: 1;
		}
		.tab-panel{
			width:100%;
			display:none;
			.text-area{
				width: 100%;
				dl{
					margin: 0;
					.title{
						font-size: 1.2rem;
						white-space: nowrap;
					}
					.content{
						margin-left: 11px;
						width: 100%;
						line-height: 1;
					}
					.subject{
						min-height: 28px;
						padding: 0px 11px;
						width: 100%; height: 100%;
						border-radius: 4px;
						border: 1px solid #dddddd;
						background-color: #ffffff;
						font-size: 1.4rem;
					}
					.textarea{
						min-height: 117px;
						padding: 11px;
						width: 100%; height: 100%;
						border-radius: 4px;
						border: 1px solid #dddddd;
						background-color: #ffffff;
						font-size: 1.4rem;
						resize: none;
						&::placeholder {
							color: #bbbbbb;
						}
						&--mail{
							min-height: 75px;
						}
					}
				}
			}
		}
		.panel2{
			dl{
				&:first-child{
					margin-bottom: 10px;
				}
			}
		}
		#tab1:checked ~ .tab-area .tab1-label{
			background:#fff3f2;
			border-right: 1px solid #fff3f2;
		}
		#tab1:checked ~ .panel-area .panel1{
			display:block;
			padding: 10px 29px 10px 20px;
			background-color:#fff3f2;
		}
		#tab2:checked ~ .tab-area .tab2-label{
			background:#fbfaf1;
			border-right: 1px solid #fbfaf1;
		}
		#tab2:checked ~ .panel-area .panel2{
			display:block;
			padding: 10px 29px 10px 20px;
			background-color:#fbfaf1;
		}
		.button-area{
			margin: 0;
			padding-left: 10px;
			margin-bottom: 3px;
			list-style: none;
			> .li-file{
				position: relative;
				padding: 0px 18px 17px 0px;
				label {
					margin-left: 19px;
					cursor: pointer;
					> input[type="file"]{
						display: none;
					}
				}
			}
			> .li-enter{
				padding-top: 10px;
				border-top: 1px solid #dddddd;
				.checkbox-text{
					position:relative;
					padding-left: 28px;
					padding-right: 28px;
					line-height: 1;
					font-size: 1.2rem;
    				display: inline;
					cursor: pointer;
					white-space: nowrap;
					&:before{
						background: #ffffff;
					}
				}
			}
			> .li-button{
				padding-top: 10px;
				button{
					padding: 7px 25px 8px 25px;
					min-width: 90px;
					font-size: 1.4rem;
					font-weight: bold;
					line-height: 1;
					color: #fc4f3f;
					border-radius: 4px;
					border: 1px solid #fc4f3f;
					background-color: #ffffff;
					text-align: center;
					cursor: pointer;
					transition: background-color .2s;
					&:hover{
						background-color: #fc4f3f;
						color: #ffffff;
					}
				}
			}
		}
	}
}

/**=========================================
* commu-telmemo
=========================================*/
.commu-telmemo{
	//overflow-x: hidden;
	position: absolute;
	top: 0; right: 0;
	width: 10px; min-height: 100vh;
	z-index: 5;
	&--active{
		width: 361px;
	}

	> .telmemo-head{
		width: 69px;
		position: absolute;
		right: 360px;
		top: 0;
		padding: 17px 10px 13px 10px;
		background-color: #eff7fc;
		border-left: 1px solid #dddddd;
		border-bottom: 1px solid #dddddd;
		border-top-left-radius : 5px;
		border-bottom-left-radius : 5px;
		z-index: 1;
		> .icon{
			display: block;
			color: #62b0e2;
			text-align: center;
		}
		> .title-h2{
			margin: 0;
			padding-top: 5px;
			font-size: 1.2rem;
		}
	}
	> .telmemo-toggle {
		overflow-x: hidden;
		position: relative;
		left: 0;
		width: 361px;
		height: 100vh;
		> .telmemo-body{
			position: absolute;
			right: 0;
			float: right;
			border-left: 1px solid #dddddd;
			background-color: #eff7fc;
			width: 100%; height: 100vh;
			padding: 23px 20px 18px 20px;
			> .title-h3{
				margin: 0;
				margin-bottom: 5px;
				font-size: 1.3rem;
				font-weight: bold;
			}
			> .text-area{
				height: calc(100vh - 120px);
				padding: 9px 0px 9px 12px;
				border-radius: 4px;
				border: 1px solid #dddddd;
				background-color: #ffffff;
				font-size: 1.4rem;
				.textarea{
					padding-right: 9px;
					width: 100%; height: 100%;
					border: none;
					font-size: 1.4rem;
					line-height: (32/15);
					resize: none;
				}
			}
			> .button-area{
				margin: 0;
				margin-top: 6px;
				padding: 0;
				list-style: none;
				label {
					margin-left: 19px;
					cursor: pointer;
					> input[type="file"]{
						display: none;
					}
				}
				button{
					padding: 7px 25px 8px 25px;
					font-size: 1.4rem;
					font-weight: bold;
					line-height: 1;
					color: #fc4f3f;
					border-radius: 4px;
					border: 1px solid #fc4f3f;
					background-color: #ffffff;
					text-align: center;
					cursor: pointer;
					transition: background-color .2s;
					&:hover{
						background-color: #fc4f3f;
						color: #ffffff;
					}
				}
			}
		}
	}
}


/**=========================================
* commu-alert-button
* アカウント検索のボタン
=========================================*/
.commu-alert-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
	> input {
		cursor: pointer;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		z-index: 2;
		width: 100%;
		opacity: 0;
		padding: 0;
	}
	> .icon{
		color: #999999;
	}
  &:before {
	content: "";
    display: block;
    position: absolute;
    top: 25px;
    left: 25px;
    border-radius: 100%;
    width: 1px;
    height: 1px;
    transition: .3s;
    transform-origin: center;
  }
  &:hover {
    &:before {
      background-color: #ffffff;
      top: 50%;
      left: 50%;
      margin-top: -20px;
      margin-left: -20px;
      width: 40px;
      height: 40px;
    }
  }
  &:after {
    @extend .fukidashi-arrow--top;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    opacity: 0;
    transition: .3s;
	}
	//コンテンツ開いた時の矢印設定
  &.is-popup-button-active {
    &:after {
      border-bottom-color: #999;
      opacity: 1;
    }
  }
  > .icon {
		position: relative;
		padding: 0px;
  }
}
.timeline-head {
	.commu-alert-button {
		&:hover {
			&:before {
				background-color: #f8f8f8;
			}
		}
	}
}

/**=========================================
* m-checkbox
* チェックボックスのデザイン
=========================================*/
.m-checkbox{
	list-style: none;
	li{
		font-size: 1.4rem;
	}
}

/**=========================================
* m-modal
* モーダルウインドウのデザイン
=========================================*/
.modal-box-base {
	height: 100%;
	z-index: 100;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	border: 0;
	padding: 0;
	position: absolute;
	display: none;
}
.modal-search-box {
	position: absolute;
	top: 0;
	width: 730px; height: 100vh;
    background-color: #f8f8f8;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
	border-radius: 4px;
	z-index: -1;
	overflow-y: scroll;
	.message-title{
		padding: 15px 20px;
		border-bottom: 1px solid #dddddd;
		.title-h3{
			margin: 0;
			font-size: 1.4rem;
		}
		.close-button{
			position: relative;
			.icon{
				font-size: 1.4rem;
				color: #999999;
			}
			&:before {
				content: "";
				display: block;
				position: absolute;
				top: 25px; left: 0px;
				border-radius: 100%;
				width: 1px; height: 1px;
				transition: .3s;
				transform-origin: center;
				z-index: -1;
			}
			&:hover {
				&:before {
					background-color: #ffffff;
					top: 50%; left: 50%;
					margin-top: -20px;
					margin-left: -20px;
					width: 40px; height: 40px;
				}
			}
		}
		
	}
	.message-search{
		position: relative;
		border-bottom: 1px solid #dddddd;
		.message-search-area{
			padding: 10px 20px;
		}
		.m-input-search{
			position: relative;
			.m-input-calendar-reset{
				.reser-btn{
					padding: 0;
					border: none;
					background: none;
				}
				.icon{
					color: #999999;
					cursor: pointer;
					&:hover{
						color: #fc4f3f;
					}
				}
			}
		}

		/**** モーダル内オプション ****/
		.option-search{
			padding-left: 10px;
			font-size: 1.4rem;
			font-weight: bold;
			color: #fc4f3f;
			border-left: 1px solid #efefef;
			.text{
				margin: 0;
			}
			.icon{
				margin-left: 6px;
				font-size: 1.2rem;
				color: #fc4f3f;
			}
		}
		.message-search-item-title{
			margin: 0 10px 0 0;
			line-height: 1;
			font-size: 1.3rem;
			color: #777777;
		}
		.message-search-item-body {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			-ms-flex-align: center;
			align-items: center;
			margin-top: 5px;
			.box{
				margin-left: 10px;
				&:first-child{
					margin-left: 0px;
				}
				.minict_wrapper {
					span{
						padding: 11px 28px 10px 10px;
					}
					&:before{
						top: 0; bottom: 0;
						margin: auto;
					}
				}
			}
		}
		.message-search-item-button{
			border-radius: 4px;
			border: 1px solid #dddddd;
			padding: 8px;
			width: 100%;
			min-height: 30px;
			font-size: 1.4rem;
			line-height: 1;
			color: #777;
			background: #ffffff;
			width: auto;
			display: inline-block;
			margin-top: 5px;
			&:hover{
				background: #f6f6f6;
			}
		}
		.message-tab{
			margin-top: 5px;
			margin-left: 10px;
			padding: 7px 14px;
			font-size: 1.2rem;
			color: #777777;
			border-radius: 20px;
			border: 1px solid #dddddd;
			background: #fbfaf1;
			.icon{
				font-size: 10px;
				margin-left: 10px;
			}
		}
		.commu-form-width--470{
			width: 470px;
		}
		.commu-form-width--98{
			width: 98px;
		}
	}

	/**** 検索結果 ****/
	.message-search-result{
		color: #777777;
		.message-search-group{
			padding: 15px 30px 0px 30px;
			.message-search-text{
				font-size: 1.4rem;
			}
			.message-search-list{
				list-style: none;
				.btn{
					margin-right: 5px;
					border-radius: 4px;
					border: 1px solid #dddddd;
					font-size: 1.4rem;
					line-height: 1;
					background: #ffffff;
					display: inline-block;
					cursor: pointer;
					label{
						cursor: pointer;
					}
					&:first-child{
						margin-right: 10px;
					}
					&:hover{
						color: #fc4f3f;
					}
				}
				.li-text{
					font-size: 1.3rem;
					.tab-box{
						display: block;
						padding: 8px 20px;
					}
				}
				.li-icon{
					padding: 7px 12px;
					font-size: 1.3rem;
					&:hover .icon{
						color: #fc4f3f;
					}
					.icon{
						font-size: 1.3rem;						
					}
				}
			}
		}
		/*ラジオボタンを全て消す*/
		input[type="radio"] {
			display: none;
		}
		/*タブ切り替えの中身のスタイル*/
		.search-tab-list {
			display: none;
			.timeline-body{
				overflow-x: hidden;
				position: relative;
				//height: 80vh;
				.timeline-data-head{
					position: relative;
					margin-top: 16px;
					border-top: 1px solid #efefef;
					> .timeline-data-container{
						position: absolute;
						top: -10px;
						width: 100%;
						text-align: center;
						line-height: 1;
						> .data{
							display: inline-flex;
							align-items: center;
							justify-content: center;
							padding: 0 5px;
							.icon{
								padding: 3px 5px 0 0;
								color: #777777;
								font-size: 1.2rem;
							}
							.taxt {
								color: #777777;
								font-size: 1.2rem;
							}
						}
					}
				}
			}
			.timeline-message{
				position: relative;
				border-top: 1px solid transparent;
				border-bottom: 1px solid transparent;
				&--border{
					border-top: 1px solid #efefef;
				}
				&--end{
					height: 24px;
				}
				.timeline-post{
					position: relative;
					padding-right: 10px;
					&--mail{
						border: 1px solid #eea900;
						.icon {
							display: block;
							overflow: hidden;
							padding-top: 7px;
							float: left;
							width: 29px; height: 29px;
							background: #eea900;
							color: #ffffff;
							text-align: center;
						}
						&:hover .post-hover{
							display: flex;
						}
						.post-hover{
							display: none;
							position: absolute;
							bottom: -16px; right: -1px;
							margin: 0;
							padding: 0px 20px 8px 20px;
							background-color: #f8f8f8;
							border-right: 1px solid #eea900;
							border-left: 1px solid #eea900;
							border-bottom: 1px solid #eea900;
							border-bottom-left-radius : 5px;
							border-bottom-right-radius : 5px;
							list-style: none;
							z-index: 2;
							.list{
								margin-left: 20px;
								font-weight: bold;
								cursor: pointer;
								&:hover{
									.icon{
										color: #fc594a;
									}
									.taxt{
										color: #fc594a;
									}
								}
								.icon{
									padding-right: 10px;
									padding-top: 0px;
									float: none;
									width: auto;
									height: auto;
									background: none;
									color: #999999;
									font-size: 1.2rem;
								}
								.taxt{
									color: #999999;
									font-size: 1.2rem;
								}
								&:first-child{
									margin-left: 0px;
								}
							}
						}
					}
					&--memo{
						border: 1px solid #62b0e2;
						.icon {
							display: block;
							overflow: hidden;
							padding-top: 7px;
							float: left;
							width: 29px; height: 29px;
							background: #62b0e2;
							color: #ffffff;
							text-align: center;
						}
						&:hover .post-hover{
							display: flex;
						}

						.post-hover{
							display: none;
							position: absolute;
							bottom: -16px; right: -1px;
							margin: 0;
							padding: 0px 20px 8px 20px;
							background-color: #f8f8f8;
							border-right: 1px solid #62b0e2;
							border-left: 1px solid #62b0e2;
							border-bottom: 1px solid #62b0e2;
							border-bottom-left-radius : 5px;
							border-bottom-right-radius : 5px;
							list-style: none;
							z-index: 2;
							.list{
								margin-left: 20px;
								font-weight: bold;
								cursor: pointer;
								&:hover{
									.icon{
										color: #fc594a;
									}
									.taxt{
										color: #fc594a;
									}
								}
								.icon{
									padding-right: 10px;
									padding-top: 0px;
									float: none;
									width: auto;
									height: auto;
									background: none;
									color: #999999;
									font-size: 1.2rem;
								}
								.taxt{
									color: #999999;
									font-size: 1.2rem;
								}
								&:first-child{
									margin-left: 0px;
								}
							}
						}
					}
					.text-post{
						margin-top: 10px;
						margin-bottom: 20px;
					}
					.carbon{
						margin-bottom: 8px;
						margin-right: 30px;
						color: #bbbbbb;
					}
					.title{
						margin: 0;
						margin: 5px 0;
						white-space: pre-wrap;
						word-wrap: break-word;
						line-height: (23/13);
						font-weight: normal;
					}
				}
				.message-body{
					margin: 10px 0;
				}
				.timeline-post-body{
					padding: 20px 30px 20px 20px;
					.member-icon {
						display: block;
						overflow: hidden;
						border-radius: 100%;
						width: 32px; height: 32px;
						float: left;
					}
					.text {
						position: relative;
						padding-left: 40px;
						padding-right: 10px;
						zoom: 1;
						font-size: 1.4rem;
						.text-width{
							width: 80%;
						}
						.name{
							margin: 0;
							margin-bottom: 8px;
							margin-right: 18px;
							font-size: 1.4rem;
							white-space: nowrap;
						}
						.data{
							position: absolute;
							top: 0; right: 0;
							font-size: 1.2rem;
						}
						.m-text-lv01{
							margin: 0;
							white-space: pre-wrap;
							word-wrap: break-word;
							line-height: (23/13);
						}
					}
				}
				.member{
					position: relative;
					padding: 10px 30px 10px 20px;
					border-top: 1px solid transparent;
					border-bottom: 1px solid transparent;
					&:hover{
						border-top: 1px solid #fda79f;
						border-bottom: 1px solid #fda79f;
						background: #fff3f2;
					}
					.member-icon {
						display: block;
						overflow: hidden;
						border-radius: 100%;
						width: 32px; height: 32px;
						float: left;
					}
					.text {
						position: relative;
						padding-left: 40px;
						padding-right: 10px;
						zoom: 1;
						font-size: 1.4rem;
						.text-width{
							width: 80%;
						}
						.name{
							margin: 0;
							margin-bottom: 8px;
							margin-right: 18px;
							font-size: 1.4rem;
							white-space: nowrap;
						}
						.data{
							position: absolute;
							top: 0; right: 0;
							font-size: 1.2rem;
						}
						.m-text-lv01{
							margin: 0;
							white-space: pre-wrap;
							word-wrap: break-word;
							line-height: (23/13);
						}
					}
					&:hover{
						.message-hover {
							display: flex;
						}
					}
					& {
						.message-hover {
							display: none;
						}
					}
					.message-hover{
						position: absolute;
						bottom: -31px; right: 30px;
						margin: 0;
						padding: 2px 20px 10px 20px;
						background-color: #fff3f2;
						border-right: 1px solid #fda79f;
						border-left: 1px solid #fda79f;
						border-bottom: 1px solid #fda79f;
						border-bottom-left-radius : 5px;
						border-bottom-right-radius : 5px;
						list-style: none;
						z-index: 2;
						.list{
							margin-left: 20px;
							font-weight: bold;
							cursor: pointer;
							&:hover{
								.icon{
									color: #fc594a;
								}
								.taxt{
									color: #fc594a;
								}
							}
							.icon{
								padding-right: 10px;
								color: #999999;
								font-size: 1.2rem;
							}
							.taxt{
								color: #999999;
								font-size: 1.2rem;
							}
							&:first-child{
								margin-left: 0px;
							}
						}

					}
				}
			}

			.account-list{
				border-bottom: 1px solid #dddddd;
				.account-block{
					display: flex;
					align-items: center;
					position: relative;
					padding: 10px 20px;
					transition: background-color .2s;
					background: #f8f8f8;
					&:hover{
						background: #efefef;
					}
					cursor: pointer;
					.member-icon{
						display: block;
						overflow: hidden;
						border-radius: 100%;
						width: 32px; height: 32px;
					}
					.text {
						display: inline-block;
						margin-left: 10px;
						font-size: 1.4rem;
						font-weight: bold;
					}
					.icon{
						position: absolute;
						margin: auto;
						right: 30px;
						font-size: 1.2rem;
					}
				}
				.member--account{
					padding: 10px 50px 10px 40px;
				}
			}
		}
		/*選択されているタブのコンテンツのみを表示*/
			#search-tab-new:checked ~ #search-tab-new-content,
			#search-tab-account:checked ~ #search-tab-account-content {
			display: block;
		}
	}
}

/**=========================================
* fullText
* サブウインドウのデザイン
=========================================*/
.full-box{
	.full-title{
		margin-right: 12px;
		padding: 20px;
		border-bottom: 1px solid #dddddd;
		font-size: 1.8rem;
		font-weight: bold;
		color: #444444;
	}
	.full-body{
		padding: 20px;
		.destination{
			margin-bottom: 10px;
			font-size: 1.6rem;
			font-weight: bold;
			color: #444444;
			.mail{
				padding-left: 5px;
				font-size: 1.4rem;
				font-weight: normal;
				color: #777777;
			}
		}
		.destination-cc{
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			margin: 0 0 10px 0;
			font-size: 1.4rem;
			color: #bbbbbb;
			.mail{
				margin: 0;
			}
		}
		.destination-to{
			font-size: 1.4rem;
			color: #777777;
			.mail{
				margin-left: 7px;
			}
		}
		.text{
			font-size: 1.4rem;
			color: #777777;
		}
	}
}

/**=========================================
* m-search-popup
* 検索結果ページのポップアップ
=========================================*/
.m-popup-content.is-popup-active.m-search-popup{
	background: #ffffff;
	box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	width: 260px;
	height: 278px;
	z-index: 110;
	position: absolute;
    left: 20px; top: auto;
}
.message-search-area .m-popup-content{
	position: absolute;
	top: auto; left: 20px;
	width: 260px;
	height: 278px;
	.search-box{
		padding: 10px 0 10px 10px;
	}
	.search-filter{
		padding: 0 10px 10px 12px;
		font-size: 1.4rem;
		color: #777777;
		.all{
			cursor: pointer;
			&:hover{
				color: #fc4f3f;
			}
		}
		.slash{
			margin: 0 2px;
		}
		.clear{
			cursor: pointer;
			&:hover{
				color: #fc4f3f;
			}
		}
	}
	.search-list{
		border-top: 1px solid #efefef;
		.m-checkbox{
			width: 100%;
			.checkbox-text{
				padding: 10px;
				width: 100%;
				background: #ffffff;
				&:hover{
					background: #efefef;
				}
				.input-icon{
					&::before{
						left: 14px;
					}
					&::after{
						left: 10px;
						width: 20px; height: 20px;
					}
				}
				.account-block{
					padding-left: 30px;
					.member-icon {
						display: block;
						overflow: hidden;
						border-radius: 100%;
						width: 32px;
						height: 32px;
					}
					.text {
						display: inline-block;
						margin-left: 10px;
						font-size: 1.4rem;
						font-weight: bold;
						color: #777777;
					}
				}
			}
		}
		.mCSB_scrollTools{
			z-index: 120;
		}
	}
}