/**
 * Base Style
 */
*,
*:after,
*:before {
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  @include font-yugo;
  margin: 0;
  min-width: 1240px;
  font-size: 1.6rem;
  line-height: (24/16);
  color: $text-base-color;
  //ガタツキ気になる時
  //overflow-y: scroll;
}
a {
  color: $link-base-color;
  text-decoration: none;
  &:active {
    text-decoration: none;
  }
}
img {
  max-width: 100%;
  vertical-align: bottom;
}
.text-feature-spacing {
  font-feature-settings : "palt" 1;//pkna
}
.icon {
  color: #999;
}
