/**
 * Header Style
 */
/**=========================================
* header
=========================================*/
.header {
  .icon {
    color: #999;
  }
  .icon-bell {
    font-size: 1.7rem;
  }
  .icon-balloon01 {
    margin-top: 2px;
    font-size: 2.2rem;
  }
}
.header-page-title {
  display: flex;
  align-items: center;
  flex-basis: auto;
  margin: 0;
  padding: 0 20px;
  font-size: 1.4rem;
  line-height: 1;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.header-button {
  display: flex;
  align-items: center;
  position: relative;
  border-left: 1px solid #efefef;
  padding: 10px 15px;
  transition: background-color .2s;
  &--member {
    margin-left: 10px;
    min-width: 158px;
    &:first-child {
      margin-left: 0;
    }
  }
  &:hover {
    background-color: #f8f8f8;
  }
  > .text {
    display: inline-block;
    margin-left: 10px;
    font-size: 1.3rem;
    &:first-child {
      margin-left: 0;
    }
  }
  > .icon {
    margin-left: 10px;
    &:first-child {
      margin-left: 0;
    }
  }
  > .icon-arrow01 {
    font-size: 1rem;
  }
  > .icon-logout {
    font-size: 1.4rem;
  }
  > .member-icon {
    display: block;
    overflow: hidden;
    border-radius: 100%;
    width: 32px;
    height: 32px;
  }
}
.header-login-name {
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px 15px;
  > .text01,
  > .text02 {
    display: inline-block;
    margin-left: 10px;
    font-size: 1.3rem;
    &:first-child {
      margin-left: 0;
    }
  }
}
/**=========================================
* header-alert-button
* お知らせ、メッセージのボタン
=========================================*/
.header-alert-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 25px;
    left: 25px;
    border-radius: 100%;
    width: 1px;
    height: 1px;
    transition: .3s;
    transform-origin: center;
  }
  &:hover,
  &.is-popup-button-active {
    &:before {
      background-color: #f8f8f8;
      top: 5px;
      left: 5px;
      width: 40px;
      height: 40px;
    }
  }
  &:after {
    @extend .fukidashi-arrow--top;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    opacity: 0;
    transition: .3s;
  }
  //コンテンツ開いた時の矢印設定
  &.is-popup-button-active {
    &:after {
      border-bottom-color: #999;
      opacity: 1;
    }
  }
  > .icon {
    position: relative;
  }
  > .number {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 7px;
    background-color: $key-color;
    border-radius: 100%;
    width: 16px;
    height: 16px;
    color: #fff;
    line-height: 1;
    > .number-text {
      display: block;
      font-size: 1rem;
      line-height: 1;
      text-align: center;
      transform: scale(0.9);
    }
  }
}
/**=========================================
* header-alert-body
* お知らせ、メッセージの中身
=========================================*/
.header-alert-body {
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  box-shadow: -2px 2px 6px 0px rgba(0, 0, 0, 0.1);
  background-color: #f8f8f8;
  border-radius: 0 0 0 4px;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  width: 476px;
  &-inner {
    //overflow-y: scroll;
    max-height: calc(100vh - 160px);
  }
}
.header-alert-item {
  display: flex;
  border-top: 1px solid #ddd;
  padding: (20-14*(22/14-1)/2)+px 10px (20-14*(22/14-1)/2)+px 20px;
  transition: .2s;
  &:hover {
    background-color: #fff;
  }
  &:first-child {
    border-top: none;
  }
  > .date {
    display: block;
    width: 86px;
    font-size: 1.3rem;
  }
  > .text {
    display: block;
    flex: 1;
    font-size: 1.4rem;
    line-height: (22/14);
    &.line-2 {
      overflow: hidden;
      //max-height: (14*2 + 14*(22/14-1)*2)+px;
      height: (14*2 + 14*(22/14-1)*2)+px;
    }
  }
  .text-from {
    display: block;
    font-size: 1.2rem;
  }
}
/**=========================================
* header-alert-more-button
* お知らせ、メッセージのもっと見るボタン
=========================================*/
.header-alert-more-button {
  display: block;
  border-top: 1px solid #ddd;
  padding: (20-14*(22/14-1)/2)+px 20px;
  font-size: 1.4rem;
  text-align: center;
  transition: .2s;
  &:hover {
    background-color: #fff;
  }
}
/**=========================================
* header-member-body
* アカウント設定の中身
=========================================*/
.header-member-body {
  position: absolute;
  top: 0;
  right: 0;
  width: 156px;
  box-shadow: -2px 2px 6px 0px rgba(0, 0, 0, 0.1);
  background-color: #f8f8f8;
  border-radius: 0 0 0 4px;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}
.header-member-link {
  display: block;
  padding: 10px 25px;
  font-size: 1.4rem;
  line-height: 1;
  transition: .2s;
  &:hover {
    background-color: #fff;
  }
}
