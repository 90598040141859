/**
 * Layout Style
**/
/**=========================================
* .l-flex-column
=========================================*/
.l-flex-column {
  display: flex;
  &.space-between {
    justify-content: space-between;
  }
  &.vertical-middle {
    align-items: center;
  }
}

/**=========================================
* .l-gnav
=========================================*/
.l-gnav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  overflow-x: hidden;
  width: 200px;
  min-height: 100vh;
  transition: width .2s ease-in-out;
  &.is-gnav-close {
    width: 50px;
  }
}
.page-backend {
  .l-gnav {
    background-color: #2d3e50;
  }
}
.page-merchant,
.page-partner {
  .l-gnav {
    background-color: #e5e7e8;
  }
}
.js-gnav-default-close {
  .l-gnav {
    transition: width .0s;
  }
}
/**=========================================
* .l-content
=========================================*/
.l-content {
  position: relative;
  margin-left: 200px;
  flex-basis: auto;
  background-color: #fff;
  min-height: 100vh;
  min-width: 1040px;
  transition: margin-left .2s ease-in-out, min-width .2s ease-in-out;
  &.is-gnav-close {
    margin-left: 50px;
    min-width: 1190px;
  }
}
.js-gnav-default-close {
  .l-content {
    transition: margin-left .0s, min-width .0s;
  }
}
/**=========================================
* .l-inner
=========================================*/
.l-inner {
  padding: 0 35px;
}
/**=========================================
* .l-page-body
=========================================*/
.l-page-body {
  padding-top: 40px;
}
/**=========================================
* .l-section--border
=========================================*/
.l-section {
  padding-top: 40px;
  &--border {
    padding-top: 40px;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 40px;
    &:last-child {
      border-bottom: none;
    }
    &.bg-gray:last-child {
      border-bottom: 1px solid #dddddd;
    }
  }
}
/**=========================================
* .l-header
=========================================*/
.l-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: #fff;
  margin-left: 200px;
  border-bottom: 1px solid $color-gray01;
  height: 50px;
  min-width: 1040px;
  transition: margin-left .2s ease-in-out, min-width .2s ease-in-out;
  &.is-gnav-close {
    margin-left: 50px;
    min-width: 1190px;
  }
}
.js-gnav-default-close {
  .l-header {
    transition: margin-left .0s, min-width .0s;
  }
}
/**=========================================
* .l-main
=========================================*/
.l-main {
  position: relative;
  z-index: 1;
  padding: 50px 0 40px;//32px
  width: 100%;
  &-inner {
    position: relative;
    width: 100%;
  }
}
.page-partner,
.page-merchant {
  .l-main {
    padding-bottom: 80px;
  }
}
/**=========================================
* .l-footer
=========================================*/
.l-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border-top: 1px solid $color-gray01;
  text-align: center;
}
