/**
* Filter Style
*/
/**=========================================
* filter
* フィルター
=========================================*/
.filter {
  background: $color-gray04;
  margin-bottom: 40px;
  border-bottom: 1px solid $color-gray01;
  opacity: 1;
  transition: opacity .3s;
  &.is-filter-hidden {
    height: 0;
    opacity: 0;
  }
  &-inner {
    position: relative;
    margin: 0 auto;
    padding-top: 5px;
    //width: 1028px;
  }
  &-body {
    padding: 35px 35px 40px;
  }
  //検索方法
  &-mode-select {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    &-title {
      @extend .m-title01;
      margin-right: 30px;
    }
  }

  &-row-4 {
    display: flex;
    margin: 24px -15px 0;
    //width: 100%;
    &:first-child {
      margin-top: 0;
    }
    > .filter-col-1 {
      padding: 0 15px;
      width: 25%;
    }
    > .filter-col-2 {
      padding: 0 15px;
      width: 50%;
    }
    > .filter-col-4 {
      padding: 0 15px;
      width: 100%;
    }
  }

  //formのかたまり
  &-item {
    &-title {
      @extend .m-title02;
      margin: 0 10px 0 0;
      line-height: 1;
    }
    &-body {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 3px;
      &:first-child {
        margin-top: 0;
      }
      &--input-text {
        > .text {
          display: inline-block;
          font-size: 1.3rem;
          font-weight: bold;
        }
      }
    }
  }
}
.filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  bottom: -39px;
  background: $color-gray04;
  margin-left: -115px;
  border-right: 1px solid $color-gray01;
  border-bottom: 1px solid $color-gray01;
  border-left: 1px solid $color-gray01;
  border-radius: 0 0 4px 4px;
  width: 230px;
  height: 40px;
  line-height: (24/14);
  text-align: center;
  input[type=submit] {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    opacity: 0;
  }
  .icon {
    color: $key-color;
  }
  .icon-search {
    margin-right: 9px;
    font-size: 1.5rem;
  }
  .icon-arrow01 {
    margin-left: 9px;
    font-size: 1.1rem;
  }
  .text {
    font-size: 1.4rem;
    color: $key-color;
    font-weight: bold;
  }
}
.filter-button-container.is-toggle-button-active {
  .icon-arrow01 {
    display: none;
  }
  input[type=submit] {
    display: block;
  }
}
