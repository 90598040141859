/**
* Table Style
*/
/**=========================================
* m-table
=========================================*/
.m-table {
  table-layout: fixed;
  margin-top: 40px;
  border-collapse: collapse;
  border-spacing: 0;
  //width: 100%;
  &:first-child {
    margin-top: 0;
  }
  &.m-table--vertical-center {
    th,
    td {
      vertical-align: middle;
    }
  }
  > thead {
    th,
    td {
      position: relative;
      border-bottom: 1px solid $color-gray02;
      padding: 20px 0;
      min-width: 90px;
      font-size: 1.2rem;
      line-height: 1;
      font-weight: bold;
      color: #444;
      text-align: center;
      vertical-align: middle;
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: $color-gray03;
        width: 1px;
      }
      &:first-child {
        &:before {
          display: none;
        }
      }
      .text {
        display: block;
      }
    }
  }
  > tbody {
    border-top: 1px solid $color-gray02;
    tr {
      background-color: $color-gray04;
    }
    tr.js-rowspan-border {
      th,
      td {
        &:before {
          display: block;
        }
      }
    }
    th,
    td {
      position: relative;
      border-bottom: 3px solid #fff;
      padding: 20px;
      min-height: 50px;
      text-align: left;
      word-break: break-all;
      vertical-align: top;
      line-height: 1;
      //左の線
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: $color-gray03;
        width: 1px;
      }
      &:first-child {
        &:before {
          display: none;
        }
      }
    }
    th {
      font-size: 1.4rem;
      width: 220px;
      .cell-text {
        padding: (6-14*(24/16-1)/2)+px 0;
        line-height: (24/16);//1.5
      }
    }
    td {
      font-size: 1.6rem;
      .cell-text {
        padding: (6-16*(24/16-1)/2)+px 0;
        line-height: (24/16);
      }
    }
  }
  &--white {
    > tbody {
      tr {
        background-color: #fff;
        th,
        td {
          border-bottom: 1px solid $color-gray01;
        }
      }
    }
  }
}
/**=========================================
* m-table--list
=========================================*/
.m-table--list-container {
  background-color: #f8f8f8;
  overflow: auto;
  margin: 10px 0 0;
  .mCSB_scrollTools {
    background-color: #fcfcfc;
  }
}
.table {
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  //width: 100%;

  &.w100 {
    width: 100%;
  }
  &:first-child {
    margin-top: 0;
  }
  tr.js-rowspan-border {
    th,
    td {
      &:before {
        display: block !important;
      }
    }
  }
  > thead {
    //border-bottom: 1px solid $color-gray02;
    th {
      border-bottom: 1px solid $color-gray02;
      &.bg-yellow,
      &.bg-blue {
        border-bottom: none;
      }
    }
  }
  > tfoot {
    border-top: 1px solid $color-gray02;
  }
  > thead,
  > tfoot {
    th,
    td {
      background-color: #fff;
      position: relative;
      padding: 0;
      min-width: 90px;
      vertical-align: middle;
      font-size: 1.2rem;
      line-height: 1.4;
      font-weight: bold;
      color: #444;
      text-align: center;
      &:focus {
        outline: none;
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: $color-gray03;
        width: 1px;
      }
      &:first-child {
        &:before {
          display: none;
        }
      }
      .cell-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        padding: (10-12*0.4/2)+px 8px;
        min-height: 50px;
      }
      .cell-action {
        padding-top: (20-18/2)+px;
        padding-bottom: (20-18/2)+px;
      }
      &.bg-yellow,
      &.bg-blue {
        .cell-inner {
          padding: 8px 8px;
          min-height: inherit;
        }
      }
    }
    tr {//tr.js-rowspan-border
      th {
        .cell-inner.cell-child {
          padding: 8px 8px;
          min-height: inherit;
        }
      }
    }
  }
  > tbody {
    tr {
      &:nth-child(odd) {
        background-color: #fff;
      }
      &:nth-child(even) {
        background-color: #f8f8f8;
      }
    }
    th,
    td {
      overflow: visible;
      position: relative;
      border-left: 1px solid $color-gray03;
      min-width: 90px;
      //min-height: 50px;
      text-align: left;
      word-break: break-all;
      vertical-align: top;//error対策で.cell-innerをflex化
      font-size: 1.3rem;
      line-height: 1;
      &:first-child {
        border-left: none;
      }
      &.show-all-text {
        white-space: normal;
        text-overflow: inherit;
      }
      &.error {
        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 1px;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #fdf3f2;
        }
      }
      .time {
        margin-left: 2px;
        font-size: 1.2rem;
        color: #bbbbbb;
      }
      .irai-icon {
        position: absolute;
        top: 50%;
        right: -6px;
        margin-top: -5px;
        .icon {
          font-size: 1rem;
          transform: scale(0.9);
          color: #999;
        }
      }
    }
  }
  th,td {
    .m-button,
    .m-button--border,
    .m-button--radius {
      position: relative;
      padding-left: 15px;
      padding-right: 15px;
      font-size: 1.2rem;
      input[type="submit"]{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        opacity: 0;
      }
    }
  }
}
// .table.error {
//   > tbody {
//     th,
//     td {
//       vertical-align: top;
//     }
//   }
// }
.cell-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 10px 8px;
  min-height: 50px;
  &.align-right {
    justify-content: flex-end;
  }
  &.align-left {
    justify-content: flex-start;
  }
  &.cell-w380,
  &.cell-w420,
  &.cell-w460,
  &.cell-w510,
  &.cell-w530,
  &.cell-w180,
  &.cell-w300,
  &.cell-w150,
  &.cell-w110-p20 {
    padding: 10px 20px;
  }
  &.cell-task-name {
    padding: 10px 20px 10px 30px;
  }
  &.cell-error {
    padding-top: 0;
    min-height: inherit;
    .text {
      margin: -3px 0 0;
      padding: 0 2px;
      width: 100%;
      font-size: 1.2rem;
      color: $color-error;
      text-align: left;
    }
  }
}

// 7
.cell-w90 {
  width: 90px;
}
// 1
.cell-w110 {
  width: 110px;
}
.cell-w110-p20 {
  width: 110px;
}
// 6
.cell-w160 {
  width: 160px;
}
// 3
.cell-w180 {
  width: 180px;
}
// 5
.cell-w380 {
  width: 380px;
}
// 2
.cell-w420 {
  width: 420px;
}
// 4
.cell-w460 {
  width: 460px;
}
// 9
.cell-w510 {
  width: 530px;
}
// 8
.cell-w530 {
  width: 530px;
}

//s000
.cell-w80 {
  width: 80px;
}
.cell-w110 {
  width: 110px;
}
.cell-w120 {
  width: 120px;
}
.cell-w150 {
  width: 160px;
}
.cell-w300,
.cell-w300-p30 {
  width: 300px;
}
//p029
.cell-w170 {
  width: 170px;
}
//s043
.cell-w100 {
  width: 100px;
}

//アクションセル
.cell-action {
  text-align: center;
  .m-form-select {
    text-align: left;
    font-size: 1.3rem;
    .minict_wrapper {
      span {
        font-size: 1.3rem;
      }
      ul {
        padding: 0;
      }
      li {
        padding: 10px 10px;
        font-size: 1.3rem;
      }
    }
  }
  .m-button,
  .m-button--border,
  .m-button--radius {
    margin: 0 auto;
  }
  input[type="text"]{
    padding: 4px 8px 3px;
    text-align: center;
  }
  label.checkbox-text {
    padding-left: 18px;
  }
  .m-input-item--term {
    padding: 0;
  }
  .ui-datepicker-trigger,
  .m-input-calendar-reset {
    display: none;
  }
}
.cell-time {
  display: flex;
  align-items: center;
  input[type="number"]{
    margin: 0 auto;
    width: 35px;
    padding: 4px 3px 3px;
    text-align: center;
  }
}
/**=========================================
* row-level--1
* s000 トグル
=========================================*/
.table {
  > tbody {
    tr {
      &.level-1 {
        background-color: #fbfaf1;
      }
      &.level-2 {
        background-color: #eff6fc;
      }
    }
  }
  .level-icon {
    position: absolute;
    top: 12px;
    left: 4px;
    width: 24px;
    height: 24px;
    .icon {
      font-size: 1rem;
      transform: scale(0.9) rotate(-90deg);
      color: #999;
      transition: .2s;
    }
  }
  .level-2 {
    td:first-child{
      padding-left: 10px;
    }
  }
}
.js-toggle-row {
  .cell-inner {
    display: flex;
    align-items: center;
    //overflow: hidden;
    transition: height .2s, paddingTop .2s, paddingBottom .2s, opacity .2s;
  }
  &.is-toggle-row-active {
    .level-icon {
      .icon {
        transform: scale(0.9) rotate(0deg);
      }
    }
  }
}
/**=========================================
* m-cell-order-block
* 順序変更
=========================================*/
th.sorting,
th.sorting_asc,
th.sorting_desc,
th.sorting_asc_disabled,
th.sorting_desc_disabled {
  background-image: none !important;
  &:hover {
    .text {
      opacity: 0.7;
    }
  }
  .text {
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 10px 10px;
    background-position: right center;
    padding-right: 13px;
  }
}
th.sorting {
  .text {
    background-image: url(../img/datatables/sort_both.png);
  }
}
th.sorting_asc {
  .text {
    background-image: url(../img/datatables/sort_asc.png);
  }
}
th.sorting_desc {
  .text {
    background-image: url(../img/datatables/sort_desc.png);
  }
}
th.sorting_asc_disabled {
  .text {
    background-image: url(../img/datatables/sort_asc_disabled.png);
  }
}
th.sorting_desc_disabled {
  .text {
    background-image: url(../img/datatables/sort_desc_disabled.png);
  }
}
/**=========================================
* cell-text_icon-block
* アイコン付きのセル
=========================================*/
.cell-text_icon-block {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 50px;
  vertical-align: middle;
  .text {
    flex: 1;
  }
  .cell-icon-button {
    display: flex;
    padding-left: 20px;
    margin-right: -10px;
    .m-icon-button {
      margin: -10px 0;
    }
    .icon {
      //font-size: 1.6rem;
    }
  }
}
/**=========================================
* data table 左右のページおくり
=========================================*/
.dataTables_length {
  .minict_wrapper {
    width: 64px;
  }
}
.dataTables_paginate {
  .previous {
    .icon-arrow01 {
      transform: rotate(90deg);
      font-size: 1rem;
    }
  }
  .next {
    .icon-arrow01 {
      transform: rotate(-90deg);
      font-size: 1rem;
    }
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  &:focus {
    outline-color: none;
    outline: none;
  }
}
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
  &:focus {
    outline-color: none;
    outline: none;
  }
}
