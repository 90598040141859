/**
 * mCustomScrollbar Style
**/
/**=========================================
* mCustomScrollbarのカスタマイズ（共通スタイル）
=========================================*/
.mCSB_draggerRail {
  display: none;
}
.mCSB_inside > .mCSB_container {
  margin: 0;
}
.mCSB_scrollTools {
  background-color: #fff;
  width: 10px;
}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  //background-color: rgba(#afc0d1,0.7);
  background-color: #dddddd;
  //margin-right: 0;
  width: 8px;
}
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  //background-color: rgba(#afc0d1,0.7);
  background-color: #dddddd;
}
.mCS-autoHide,
.mCustomScrollBox {
  .mCSB_scrollTools {
    opacity: 1;
    transition: 0s;
  }
}
//横方向スクロール
.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-bottom: 10px;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  height: 10px;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  background-color: #dddddd;
  margin: 1px auto;
  height: 8px;
}
